import React, { FC, useState } from "react";

import styled from "@emotion/styled";
import Cookies from "js-cookie";
import get from "lodash.get";
import { useApolloClient } from "react-apollo";

import Logo from "../../ui/assets/Logo";
import theme from "../../ui/theme";
import { AuthContext, AuthProvider } from "./AuthContext";
import {
  AuthAdmin,
  AuthAdminVariables,
} from "./graphql/mutation/__generated__/AuthAdmin";
import AUTH_ADMIN from "./graphql/mutation/login.graphql";
import Login from "./Login";

const Authentication: FC = ({ children }) => {
  const client = useApolloClient();
  const [error, setError] = useState("");
  const [isCaptchaRequired, setCaptchaRequired] = useState(false);
  const [needPasswordChange, setPasswordChanging] = useState(false);

  const handleSubmit = ({ email, password, recaptcha }, logIn) => {
    const variables: AuthAdminVariables = {
      email,
      password,
      captcha: isCaptchaRequired ? recaptcha : undefined,
    };

    return client
      .mutate<AuthAdmin, AuthAdminVariables>({
        mutation: AUTH_ADMIN,
        variables,
      })
      .then((response) => {
        const success = get(response, "data.auth.login.success");
        const error = get(response, "data.auth.login.errors") || [];

        if (!success) {
          if (error.includes("AUTH_ERROR_CAPTCHA_REQUIRED")) {
            setCaptchaRequired(true);
            setError("Use captcha.");

            return;
          }

          setError("Wrong email or password. Try other.");

          return;
        } else {
          const needToChangePassword = false;
          // TODO There's no changePassword field in mutation results
          ///response?.data?.auth?.login?.account.changePassword;

          if (needToChangePassword) {
            setPasswordChanging(true);
          } else {
            const login = response.data?.auth?.login;

            if (login != null) {
              const adminId = login.account.id;
              const accessToken = login.access_token;
              const refreshToken = login.refresh_token;

              logIn();
              Cookies.set("accessToken", accessToken, {
                expires: 1,
                path: "/",
              });
              Cookies.set("refreshToken", refreshToken, {
                expires: 7,
                path: "/",
              });
              window.localStorage.setItem("adminId", adminId);
            }
          }
        }
      });
  };

  return (
    <AuthProvider>
      <AuthContext.Consumer>
        {({ loggedIn, logIn }) => (
          <>
            {loggedIn && children}
            {!loggedIn && (
              <LoginForm>
                <Header>
                  <Logo />
                </Header>
                {needPasswordChange ? (
                  <div>needPasswordChange</div>
                ) : (
                  <Login
                    errorMessage={error}
                    handleCloseError={() => {
                      setError("");
                    }}
                    isCaptchaRequired={isCaptchaRequired}
                    onSubmit={(props) => handleSubmit(props, logIn)}
                  />
                )}
              </LoginForm>
            )}
          </>
        )}
      </AuthContext.Consumer>
    </AuthProvider>
  );
};

const Header = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: white;
  padding: 0 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${theme.boxShadow.grey};
`;
const LoginForm = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0 120px;
  background: ${theme.colors.main.lightGrey};
`;

export default Authentication;
