import styled from "@emotion/styled";

import theme from "../theme";

import css from "@emotion/css";

const Button = styled.button<{ loading?: boolean }>`
  min-height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 40px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  transition: all 0.25s;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &[disabled],
  &[disabled]:hover {
    cursor: default;
    box-shadow: none;
    opacity: 0.25;
  }

  [class="spinner"] {
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 3;
    border-radius: 5px;
    background: #00000077;
    display: none;
  }

  ${({ loading }) =>
    loading
      ? css`
          [class="spinner"] {
            display: flex;
          }

          [class="text"] {
            opacity: 0;
          }
        `
      : ""}
`;

export const PrimaryButton = styled(Button)`
  color: #fff;
  background: ${theme.colors.main.mainBlue};
  box-shadow: 0 10px 30px 0 rgba(0, 184, 255, 0.5);
  border: 0;

  &:hover {
    background: #008bc1;
    box-shadow: 0 15px 30px rgba(0, 139, 193, 0.5);
  }

  &[disabled],
  &[disabled]:hover {
    background-color: #00b8ff;
  }
`;

export const SecondaryButton = styled(Button)`
  color: ${theme.colors.main.darkGrey};
  background: white;
  border: solid 1px ${theme.colors.main.midGrey};

  &:hover {
    background: ${theme.colors.main.lightGrey};
  }

  &[disabled],
  &[disabled]:hover {
    background-color: ${theme.colors.main.midGrey};
  }
`;

export const DeleteButton = styled(Button)`
  color: #fff;
  background: ${theme.colors.main.red};
  box-shadow: 0 10px 30px 0 rgba(255, 0, 0, 0.25);
  border: 0;

  &:hover {
    background: ${theme.colors.main.darkRed};
    box-shadow: 0 15px 30px 0 rgba(205, 0, 0, 0.25);
  }

  &[disabled],
  &[disabled]:hover {
    background-color: ${theme.colors.main.red};
  }
`;

export const SuccessButton = styled(Button)`
  color: #fff;
  background: ${theme.colors.main.green};
  box-shadow: 0 10px 30px 0 rgba(0, 255, 0, 0.25);
  border: 0;

  &:hover {
    background: ${theme.colors.main.midGreen};
    box-shadow: 0 15px 30px 0 rgba(0, 205, 0, 0.25);
  }

  &[disabled],
  &[disabled]:hover {
    background-color: ${theme.colors.main.green};
    opacity: 0.25;
  }
`;
