import { useState } from "react";

type Props = {
  children: any;
  opened: boolean;
};

const Collapse = (props: Props) => {
  const [isOpened, toggle] = useState(props.opened);

  return props.children({
    isOpened,
    toggle: () => toggle(!isOpened),
  });
};

export default Collapse;
