import React, { FC } from "react";

import { css, keyframes } from "@emotion/core";
import styled from "@emotion/styled";

type SpinnerSize = "sm-x" | "sm" | "md" | "lg";

const Spinner: FC<{ size?: SpinnerSize; color?: string }> = ({
  size = "md",
  color = "#00b8ff",
}) => (
  <SpinnerContainer size={size}>
    <SpinnerIcon color={color} />
  </SpinnerContainer>
);

function SpinnerIcon({ color }) {
  return (
    <svg
      height="512px"
      viewBox="0 0 228.08 228.08"
      width="512px"
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      y="0px"
    >
      <g>
        <g>
          <path
            d="M114.054,159.842c-4.142,0-7.5,3.358-7.5,7.5v53.237c0,4.142,3.358,7.5,7.5,7.5c4.143,0,7.5-3.358,7.5-7.5v-53.237   C121.554,163.2,118.197,159.842,114.054,159.842z"
            fill={color}
          />
          <path
            d="M114.054,0c-4.142,0-7.5,3.358-7.5,7.5v53.224c0,4.142,3.358,7.5,7.5,7.5c4.143,0,7.5-3.358,7.5-7.5V7.5   C121.554,3.358,118.197,0,114.054,0z"
            fill={color}
          />
          <path
            d="M68.287,114.054c0-4.142-3.357-7.5-7.5-7.5H7.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h53.287   C64.93,121.554,68.287,118.196,68.287,114.054z"
            fill={color}
          />
          <path
            d="M220.58,106.554h-53.26c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h53.26c4.142,0,7.5-3.358,7.5-7.5   S224.722,106.554,220.58,106.554z"
            fill={color}
          />
          <path
            d="M71.069,146.403l-37.658,37.669c-2.929,2.93-2.928,7.678,0.001,10.607c1.465,1.464,3.384,2.196,5.303,2.196   c1.92,0,3.839-0.732,5.304-2.197l37.658-37.669c2.929-2.93,2.928-7.678-0.002-10.607C78.745,143.473,73.996,143.473,71.069,146.403   z"
            fill={color}
          />
          <path
            d="M71.031,81.64c1.464,1.465,3.384,2.197,5.303,2.197c1.919,0,3.839-0.732,5.304-2.197c2.929-2.929,2.929-7.678-0.001-10.606   L44.018,33.416c-2.929-2.93-7.677-2.929-10.606,0c-2.929,2.929-2.929,7.678,0.001,10.606L71.031,81.64z"
            fill={color}
          />
          <path
            d="M157.034,146.452c-2.931-2.928-7.68-2.927-10.606,0.003c-2.929,2.93-2.927,7.679,0.003,10.607l37.641,37.617   c1.465,1.463,3.383,2.195,5.302,2.195c1.919,0,3.841-0.733,5.305-2.198c2.929-2.93,2.927-7.679-0.003-10.607L157.034,146.452z"
            fill={color}
          />
        </g>
      </g>{" "}
    </svg>
  );
}

export default Spinner;

const spinnerAnimation = keyframes`
  0%,
  100% {
    transform: rotate(315deg);
  }
  12% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(45deg);
  }
  36% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(135deg);
  }
  62% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(225deg);
  }
  87% {
    transform: rotate(270deg);
  }
`;

const SpinnerContainer = styled.div<{ size: SpinnerSize }>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  animation: ${spinnerAnimation} 800ms step-end infinite;

  ${({ size }) =>
    size === "sm-x"
      ? css`
          padding: 0;

          svg {
            width: 24px;
            height: 24px;
          }
        `
      : size === "sm"
      ? css`
          svg {
            width: 24px;
            height: 24px;
          }
        `
      : size === "md"
      ? css`
          svg {
            width: 50px;
            height: 50px;
          }
        `
      : size === "lg"
      ? css`
          svg {
            width: 100px;
            height: 100px;
          }
        `
      : ""}
`;
