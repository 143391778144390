import { ReactNode } from "react";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 } from "uuid";

export enum Severity {
  Error,
  Warning,
  Info,
  Success,
  Default,
}

type NotificationsState = {
  message: ReactNode;
  severity: Severity;
  uuid: string;
}[];

const slice = createSlice({
  name: "notifications",
  initialState: [] as NotificationsState,
  reducers: {
    showNotification(
      state,
      action: PayloadAction<{ message: string; severity: Severity }>
    ) {
      state.push({ ...action.payload, uuid: v4() });
    },
    notificationTimedOut(state, action: PayloadAction<string>) {
      return state.filter((n) => n.uuid !== action.payload);
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
