import React from "react";

import { TagsOutlined } from "@ant-design/icons";
import AntdButton from "antd-button-color";
import { Link, useHistory } from "react-router-dom";

import Logout from "../../entities/auth/Logout";
import Logo from "../../ui/assets/Logo";

import styles from "./styles.module.css";

const Header = () => (
  <header className={styles.header}>
    <Link to="/">
      <Logo />
    </Link>

    <div className={styles.right}>
      <RemoteSales />
      <Logout />
    </div>
  </header>
);

const RemoteSales = () => {
  const REMOTE_SALES_PAGE = "/remote-sales";
  const history = useHistory();

  return (
    <AntdButton
      icon={<TagsOutlined />}
      onClick={() => history.push({ pathname: REMOTE_SALES_PAGE })}
    >
      Remote Sales
    </AntdButton>
  );
};

export default Header;
