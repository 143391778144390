/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum account_api_AccountCreationTypeEnum {
  ACCOUNT_CREATION_TYPE_EMAIL = "ACCOUNT_CREATION_TYPE_EMAIL",
  ACCOUNT_CREATION_TYPE_GOOGLE = "ACCOUNT_CREATION_TYPE_GOOGLE",
}

export enum account_api_AccountGenderEnum {
  FEMALE = "FEMALE",
  MALE = "MALE",
  UNKNOWN_GENDER = "UNKNOWN_GENDER",
}

export enum account_api_AccountPhoneTypeEnum {
  PHONE_TYPE_HOME = "PHONE_TYPE_HOME",
  PHONE_TYPE_MOBILE = "PHONE_TYPE_MOBILE",
  PHONE_TYPE_WORK = "PHONE_TYPE_WORK",
  UNKNOWN_PHONE_TYPE = "UNKNOWN_PHONE_TYPE",
}

export enum account_api_AuthorizationOriginEnum {
  AUTHORIZATION_ORIGIN_RIGHTMOVE = "AUTHORIZATION_ORIGIN_RIGHTMOVE",
  AUTHORIZATION_ORIGIN_UNKNOWN = "AUTHORIZATION_ORIGIN_UNKNOWN",
  AUTHORIZATION_ORIGIN_ZOOPLA = "AUTHORIZATION_ORIGIN_ZOOPLA",
}

export enum account_api_EntryPointEnum {
  ENTRY_POINT_ADMIN_REMOTE_SALE = "ENTRY_POINT_ADMIN_REMOTE_SALE",
  ENTRY_POINT_BOOK_MORTGAGE_ADVICE = "ENTRY_POINT_BOOK_MORTGAGE_ADVICE",
  ENTRY_POINT_COMPLIANCE_EICR = "ENTRY_POINT_COMPLIANCE_EICR",
  ENTRY_POINT_COMPLIANCE_EPC = "ENTRY_POINT_COMPLIANCE_EPC",
  ENTRY_POINT_COMPLIANCE_GAS_SAFETY = "ENTRY_POINT_COMPLIANCE_GAS_SAFETY",
  ENTRY_POINT_CONTENT_BLOG_POST = "ENTRY_POINT_CONTENT_BLOG_POST",
  ENTRY_POINT_CONTENT_WEBINAR = "ENTRY_POINT_CONTENT_WEBINAR",
  ENTRY_POINT_GET_FREE_ADVICE = "ENTRY_POINT_GET_FREE_ADVICE",
  ENTRY_POINT_INSURANCE_HEI = "ENTRY_POINT_INSURANCE_HEI",
  ENTRY_POINT_INSURANCE_RGI = "ENTRY_POINT_INSURANCE_RGI",
  ENTRY_POINT_JOIN_MASHROOM = "ENTRY_POINT_JOIN_MASHROOM",
  ENTRY_POINT_LISTING = "ENTRY_POINT_LISTING",
  ENTRY_POINT_MANAGEMENT_ADVANCE_RENT = "ENTRY_POINT_MANAGEMENT_ADVANCE_RENT",
  ENTRY_POINT_MANAGEMENT_DEPOSIT_REPLACEMENT = "ENTRY_POINT_MANAGEMENT_DEPOSIT_REPLACEMENT",
  ENTRY_POINT_MANAGEMENT_INVENTORY = "ENTRY_POINT_MANAGEMENT_INVENTORY",
  ENTRY_POINT_MANAGEMENT_MAINTENANCE = "ENTRY_POINT_MANAGEMENT_MAINTENANCE",
  ENTRY_POINT_MANAGEMENT_PROFESSIONAL_PHOTOGRAPHY = "ENTRY_POINT_MANAGEMENT_PROFESSIONAL_PHOTOGRAPHY",
  ENTRY_POINT_MANAGEMENT_TENANT_REFERENCING = "ENTRY_POINT_MANAGEMENT_TENANT_REFERENCING",
  ENTRY_POINT_MORTGAGE_BUY_TO_LET = "ENTRY_POINT_MORTGAGE_BUY_TO_LET",
  ENTRY_POINT_MORTGAGE_FIRST_TIME_BUY = "ENTRY_POINT_MORTGAGE_FIRST_TIME_BUY",
  ENTRY_POINT_MORTGAGE_REMORTGAGE = "ENTRY_POINT_MORTGAGE_REMORTGAGE",
  ENTRY_POINT_PAYMENT_POST = "ENTRY_POINT_PAYMENT_POST",
  ENTRY_POINT_TENANT_BROWSE_HOME = "ENTRY_POINT_TENANT_BROWSE_HOME",
  ENTRY_POINT_TENANT_CONTENTS_INSURANCE = "ENTRY_POINT_TENANT_CONTENTS_INSURANCE",
  ENTRY_POINT_TENANT_DEPOSIT_REPLACEMENT = "ENTRY_POINT_TENANT_DEPOSIT_REPLACEMENT",
  ENTRY_POINT_TENANT_MESSAGE_PROPERTY = "ENTRY_POINT_TENANT_MESSAGE_PROPERTY",
  ENTRY_POINT_TENANT_OFFER_PROPERTY = "ENTRY_POINT_TENANT_OFFER_PROPERTY",
  ENTRY_POINT_TENANT_TEAM_UP = "ENTRY_POINT_TENANT_TEAM_UP",
  ENTRY_POINT_TENANT_VIEW_PROPERTY = "ENTRY_POINT_TENANT_VIEW_PROPERTY",
  ENTRY_POINT_TOOL_CERTIFICATE_REPOSITORY = "ENTRY_POINT_TOOL_CERTIFICATE_REPOSITORY",
  ENTRY_POINT_TOOL_EXPENSE_TRACKER = "ENTRY_POINT_TOOL_EXPENSE_TRACKER",
  ENTRY_POINT_TOOL_MARKET_VALUATION = "ENTRY_POINT_TOOL_MARKET_VALUATION",
  ENTRY_POINT_UNKNOWN = "ENTRY_POINT_UNKNOWN",
  ENTRY_POINT_WEBINAR = "ENTRY_POINT_WEBINAR",
  ENTRY_POINT_ZOOM = "ENTRY_POINT_ZOOM",
}

export enum account_api_OwnershipStatusEnum {
  OWNERSHIP_APPROVED = "OWNERSHIP_APPROVED",
  OWNERSHIP_DECLINED = "OWNERSHIP_DECLINED",
  OWNERSHIP_INITIAL = "OWNERSHIP_INITIAL",
  OWNERSHIP_PENDING = "OWNERSHIP_PENDING",
}

export enum account_api_VeriffStatusEnum {
  ABANDONED = "ABANDONED",
  APPROVED = "APPROVED",
  CREATED = "CREATED",
  DECLINED = "DECLINED",
  EXPIRED = "EXPIRED",
  RESUBMISSION_REQUESTED = "RESUBMISSION_REQUESTED",
  REVIEW = "REVIEW",
  UNDEFINED = "UNDEFINED",
  UNKNOWN = "UNKNOWN",
}

export enum auth_api_AuthErrorsEnum {
  AUTH_ERROR_ACCOUNT_DOES_NOT_EXIST = "AUTH_ERROR_ACCOUNT_DOES_NOT_EXIST",
  AUTH_ERROR_ACCOUNT_IS_BLOCKED = "AUTH_ERROR_ACCOUNT_IS_BLOCKED",
  AUTH_ERROR_ACCOUNT_IS_LINKED_WITH_GOOGLE = "AUTH_ERROR_ACCOUNT_IS_LINKED_WITH_GOOGLE",
  AUTH_ERROR_CAPTCHA_INVALID = "AUTH_ERROR_CAPTCHA_INVALID",
  AUTH_ERROR_CAPTCHA_REQUIRED = "AUTH_ERROR_CAPTCHA_REQUIRED",
  AUTH_ERROR_EMAIL_INVALID = "AUTH_ERROR_EMAIL_INVALID",
  AUTH_ERROR_EMAIL_OTP_EXPIRED = "AUTH_ERROR_EMAIL_OTP_EXPIRED",
  AUTH_ERROR_EMAIL_OTP_INVALID = "AUTH_ERROR_EMAIL_OTP_INVALID",
  AUTH_ERROR_EMAIL_OTP_REQUIRED = "AUTH_ERROR_EMAIL_OTP_REQUIRED",
  AUTH_ERROR_EMAIL_TAKEN = "AUTH_ERROR_EMAIL_TAKEN",
  AUTH_ERROR_INVALID_PASSWORD = "AUTH_ERROR_INVALID_PASSWORD",
  AUTH_ERROR_LOGIN_REQUIRED = "AUTH_ERROR_LOGIN_REQUIRED",
  AUTH_ERROR_PASSWORD_REQUIRED = "AUTH_ERROR_PASSWORD_REQUIRED",
  AUTH_ERROR_PASSWORD_TOO_LONG = "AUTH_ERROR_PASSWORD_TOO_LONG",
  AUTH_ERROR_PASSWORD_TOO_SHORT = "AUTH_ERROR_PASSWORD_TOO_SHORT",
  AUTH_ERROR_PASSWORD_TOO_SIMPLE = "AUTH_ERROR_PASSWORD_TOO_SIMPLE",
  AUTH_ERROR_PHONE_EXISTS = "AUTH_ERROR_PHONE_EXISTS",
  AUTH_ERROR_PHONE_OTP_EXPIRED = "AUTH_ERROR_PHONE_OTP_EXPIRED",
  AUTH_ERROR_PHONE_OTP_INVALID = "AUTH_ERROR_PHONE_OTP_INVALID",
  AUTH_ERROR_PHONE_OTP_REQUIRED = "AUTH_ERROR_PHONE_OTP_REQUIRED",
  AUTH_ERROR_PHONE_TAKEN = "AUTH_ERROR_PHONE_TAKEN",
  AUTH_ERROR_PIN_INVALID = "AUTH_ERROR_PIN_INVALID",
  AUTH_ERROR_TOS_REVIEW_INVALID = "AUTH_ERROR_TOS_REVIEW_INVALID",
  AUTH_ERROR_TOS_REVIEW_REQUIRED = "AUTH_ERROR_TOS_REVIEW_REQUIRED",
  AUTH_ERROR_VERIFICATION_INVALID = "AUTH_ERROR_VERIFICATION_INVALID",
  AUTH_ERROR_VERIFICATION_REQUIRED = "AUTH_ERROR_VERIFICATION_REQUIRED",
  UNKNOWN_AUTH_ERROR = "UNKNOWN_AUTH_ERROR",
}

export enum cardpayment_api_MandateStatusEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
}

export enum cardpayment_api_PaymentStatusEnum {
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  PROCESSING = "PROCESSING",
  SUCCEEDED = "SUCCEEDED",
  UNKNOWN_PAYMENT_STATUS = "UNKNOWN_PAYMENT_STATUS",
  UNPAID = "UNPAID",
}

export enum cardpayment_api_PaymentTypeEnum {
  ACCOUNT = "ACCOUNT",
  BANK_ACCOUNT = "BANK_ACCOUNT",
  BITCOIN = "BITCOIN",
  CARD = "CARD",
  DIRECT_DEBIT = "DIRECT_DEBIT",
  DIRECT_DEBIT_WITH_MANDATE = "DIRECT_DEBIT_WITH_MANDATE",
  SOURCE = "SOURCE",
  SUBSCRIPTION = "SUBSCRIPTION",
  UNKNOWN_PAYMENT_TYPE = "UNKNOWN_PAYMENT_TYPE",
}

export enum comments_api_CorrelationTypeEnum {
  CORRELATION_TYPE_BLOG_POST = "CORRELATION_TYPE_BLOG_POST",
  CORRELATION_TYPE_UNKNOWN = "CORRELATION_TYPE_UNKNOWN",
}

export enum comments_api_StatusEnum {
  STATUS_ACTIVE = "STATUS_ACTIVE",
  STATUS_DISABLED = "STATUS_DISABLED",
  STATUS_UNKNOWN = "STATUS_UNKNOWN",
}

export enum common_api_generic_query_QueryFilterOperatorEnum {
  ALL = "ALL",
  ANY = "ANY",
  CONTAINS = "CONTAINS",
  CONTAINS_ANY = "CONTAINS_ANY",
  DASH = "DASH",
  DASH_ANY = "DASH_ANY",
  DASH_CONTAINS = "DASH_CONTAINS",
  EQUALS = "EQUALS",
  FREEFORM = "FREEFORM",
  LESS = "LESS",
  MORE = "MORE",
  ONEOF = "ONEOF",
  RANGE = "RANGE",
  STARTWITH = "STARTWITH",
  UNKNOWN_OPERATOR = "UNKNOWN_OPERATOR",
}

export enum common_api_generic_query_QueryFilterTransformationPreprocessorEnum {
  REGEXP_REPLACE = "REGEXP_REPLACE",
  REPLACE = "REPLACE",
  UNKNOWN_TRANSFORMATION_PREPROCESSOR = "UNKNOWN_TRANSFORMATION_PREPROCESSOR",
}

export enum document_api_DocumentStateEnum {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  UNAPPROVED = "UNAPPROVED",
  UNKNOWN_DOCUMENT_STATE = "UNKNOWN_DOCUMENT_STATE",
}

export enum document_api_DocumentTypeEnum {
  BILL = "BILL",
  CERTIFICATE = "CERTIFICATE",
  CHECK_IN_REPORT = "CHECK_IN_REPORT",
  CHECK_OUT_REPORT = "CHECK_OUT_REPORT",
  CONTENTS_INSURANCE = "CONTENTS_INSURANCE",
  CONTRACT = "CONTRACT",
  DEPOSIT_REGISTRATION_CERTIFICATE = "DEPOSIT_REGISTRATION_CERTIFICATE",
  HOME_EMERGENCY_INSURANCE = "HOME_EMERGENCY_INSURANCE",
  IDENTITY = "IDENTITY",
  INVENTORY = "INVENTORY",
  LIFE_INSURANCE = "LIFE_INSURANCE",
  MAINTENANCE = "MAINTENANCE",
  OTHER = "OTHER",
  PHOTO = "PHOTO",
  PHOTO_RAW = "PHOTO_RAW",
  PLAN = "PLAN",
  PLAN_RAW = "PLAN_RAW",
  REFERENCING_REPORT = "REFERENCING_REPORT",
  RENT_GUARANTEE_INSURANCE = "RENT_GUARANTEE_INSURANCE",
  UNKNOWN_TYPE = "UNKNOWN_TYPE",
}

export enum expenses_api_AdvanceStatusEnum {
  ADVANCE_STATUS_CANCELLED = "ADVANCE_STATUS_CANCELLED",
  ADVANCE_STATUS_DENIED = "ADVANCE_STATUS_DENIED",
  ADVANCE_STATUS_GRANTED = "ADVANCE_STATUS_GRANTED",
  ADVANCE_STATUS_REQUESTED = "ADVANCE_STATUS_REQUESTED",
  ADVANCE_STATUS_UNKNOWN = "ADVANCE_STATUS_UNKNOWN",
}

export enum expenses_api_CorrelationTypeEnum {
  CORRELATION_TYPE_OFFER = "CORRELATION_TYPE_OFFER",
  CORRELATION_TYPE_PROPERTY = "CORRELATION_TYPE_PROPERTY",
  CORRELATION_TYPE_UNKNOWN = "CORRELATION_TYPE_UNKNOWN",
  CORRELATION_TYPE_USER = "CORRELATION_TYPE_USER",
}

export enum expenses_api_PayoutStatusEnum {
  PAYOUT_STATUS_CONFIRMED = "PAYOUT_STATUS_CONFIRMED",
  PAYOUT_STATUS_PAID = "PAYOUT_STATUS_PAID",
  PAYOUT_STATUS_PENDING = "PAYOUT_STATUS_PENDING",
  PAYOUT_STATUS_UNKNOWN = "PAYOUT_STATUS_UNKNOWN",
}

export enum extras_api_ServiceRoleEnum {
  LANDLORD_CLAIM = "LANDLORD_CLAIM",
  LANDLORD_INSURANCE = "LANDLORD_INSURANCE",
  LANDLORD_LISTING = "LANDLORD_LISTING",
  LANDLORD_SERVICES = "LANDLORD_SERVICES",
  MANAGER_SERVICES = "MANAGER_SERVICES",
  MORTGAGE = "MORTGAGE",
  PRICING = "PRICING",
  TENANT_DEPOSIT = "TENANT_DEPOSIT",
  TENANT_INSURANCE = "TENANT_INSURANCE",
  TENANT_SERVICES = "TENANT_SERVICES",
  UNKNOWN_SERVICE_ROLE = "UNKNOWN_SERVICE_ROLE",
}

export enum extras_api_ServiceStatusEnum {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  PENDING = "PENDING",
  UNKNOWN_SERVICE_STATUS = "UNKNOWN_SERVICE_STATUS",
}

export enum extras_api_ServiceTypeEnum {
  ADVANCED_RENT_PLAN = "ADVANCED_RENT_PLAN",
  BUILDINGS_INSURANCE = "BUILDINGS_INSURANCE",
  BUNDLE = "BUNDLE",
  CERTIFICATE = "CERTIFICATE",
  CHECK_IN_REPORT = "CHECK_IN_REPORT",
  CHECK_OUT_REPORT = "CHECK_OUT_REPORT",
  CONTENTS_INSURANCE = "CONTENTS_INSURANCE",
  DAMAGE_SETTLEMENT = "DAMAGE_SETTLEMENT",
  DEPOSIT = "DEPOSIT",
  DEPOSIT_INSURANCE = "DEPOSIT_INSURANCE",
  EIC_REPORT = "EIC_REPORT",
  EXTRA_LISTING = "EXTRA_LISTING",
  HOME_EMERGENCY_INSURANCE = "HOME_EMERGENCY_INSURANCE",
  INVENTORY_REPORT = "INVENTORY_REPORT",
  LEGAL_EXPENSES_INSURANCE = "LEGAL_EXPENSES_INSURANCE",
  LISTING_FEE = "LISTING_FEE",
  PAYOUT = "PAYOUT",
  PHOTOS_FLOOR_PLAN = "PHOTOS_FLOOR_PLAN",
  REFERENCING_CHECK = "REFERENCING_CHECK",
  RENT = "RENT",
  RENT_GUARANTEE_INSURANCE = "RENT_GUARANTEE_INSURANCE",
  UNKNOWN_SERVICE_TYPE = "UNKNOWN_SERVICE_TYPE",
}

export enum extras_api_TaxTypeEnum {
  TAX_TYPE_IPT = "TAX_TYPE_IPT",
  TAX_TYPE_NONE = "TAX_TYPE_NONE",
  TAX_TYPE_VAT = "TAX_TYPE_VAT",
  UNKNOWN_TAX_TYPE = "UNKNOWN_TAX_TYPE",
}

export enum invitation_api_InvitationStatusEnum {
  INVITATION_STATUS_ACCEPTED = "INVITATION_STATUS_ACCEPTED",
  INVITATION_STATUS_CANCELED = "INVITATION_STATUS_CANCELED",
  INVITATION_STATUS_DECLINED = "INVITATION_STATUS_DECLINED",
  INVITATION_STATUS_PENDING = "INVITATION_STATUS_PENDING",
  INVITATION_STATUS_TIMEOUT = "INVITATION_STATUS_TIMEOUT",
  INVITATION_STATUS_UNKNOWN = "INVITATION_STATUS_UNKNOWN",
}

export enum invitation_api_InvitationTypeEnum {
  INVITATION_TYPE_LANDLORD_TO_MANAGER = "INVITATION_TYPE_LANDLORD_TO_MANAGER",
  INVITATION_TYPE_RELATED_TO_LANDLORD = "INVITATION_TYPE_RELATED_TO_LANDLORD",
  INVITATION_TYPE_RELATED_TO_TENANT = "INVITATION_TYPE_RELATED_TO_TENANT",
  INVITATION_TYPE_UNKNOWN = "INVITATION_TYPE_UNKNOWN",
}

export enum invoice_api_InvoiceStatusEnum {
  INVOICE_CANCELED = "INVOICE_CANCELED",
  INVOICE_FULFILLED = "INVOICE_FULFILLED",
  INVOICE_OPEN = "INVOICE_OPEN",
  UNKNOWN_INVOICE_STATUS = "UNKNOWN_INVOICE_STATUS",
}

export enum invoice_api_InvoiceTypeEnum {
  INVOICE_TYPE_PAYMENT = "INVOICE_TYPE_PAYMENT",
  INVOICE_TYPE_PAYOUT = "INVOICE_TYPE_PAYOUT",
  INVOICE_TYPE_ROLLING = "INVOICE_TYPE_ROLLING",
  INVOICE_TYPE_UNKNOWN = "INVOICE_TYPE_UNKNOWN",
}

export enum invoice_api_OrderSourceEnum {
  DOCUMENTS = "DOCUMENTS",
  ECOMMERCE = "ECOMMERCE",
  EXTERNAL = "EXTERNAL",
  LISTING = "LISTING",
  OFFER = "OFFER",
  ONLINE = "ONLINE",
  REMOTE_SALES = "REMOTE_SALES",
  RENT_COLLECTION = "RENT_COLLECTION",
  UNKNOWN = "UNKNOWN",
}

export enum invoice_api_OrderStatusEnum {
  ORDER_ACTIVE = "ORDER_ACTIVE",
  ORDER_CANCELLED = "ORDER_CANCELLED",
  ORDER_PAID = "ORDER_PAID",
  ORDER_PENDING = "ORDER_PENDING",
  UNKNOWN_ORDER_STATUS = "UNKNOWN_ORDER_STATUS",
}

export enum invoice_api_OrderTypeEnum {
  CONTENTS_INSURANCE_ORDER = "CONTENTS_INSURANCE_ORDER",
  INDIVIDUAL_ORDER = "INDIVIDUAL_ORDER",
  ORDER_TYPE_UNKNOWN = "ORDER_TYPE_UNKNOWN",
  REGULAR_ORDER = "REGULAR_ORDER",
}

export enum invoice_api_PaymentStatusEnum {
  PAYMENT_CANCELED = "PAYMENT_CANCELED",
  PAYMENT_CONFIRM = "PAYMENT_CONFIRM",
  PAYMENT_ESTABLISHED = "PAYMENT_ESTABLISHED",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PAYMENT_SUBSCRIBED = "PAYMENT_SUBSCRIBED",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",
  UNKNOWN_PAYMENT_STATUS = "UNKNOWN_PAYMENT_STATUS",
}

export enum invoice_api_PeriodicPaymentFrequencyEnum {
  FREQUENCY_DAILY = "FREQUENCY_DAILY",
  FREQUENCY_MONTHLY = "FREQUENCY_MONTHLY",
  FREQUENCY_QUARTERLY = "FREQUENCY_QUARTERLY",
  FREQUENCY_TWICE_A_YEAR = "FREQUENCY_TWICE_A_YEAR",
  FREQUENCY_TWICE_MONTHLY = "FREQUENCY_TWICE_MONTHLY",
  FREQUENCY_TWICE_WEEKLY = "FREQUENCY_TWICE_WEEKLY",
  FREQUENCY_UNKNOWN = "FREQUENCY_UNKNOWN",
  FREQUENCY_WEEKLY = "FREQUENCY_WEEKLY",
  FREQUENCY_YEARLY = "FREQUENCY_YEARLY",
}

export enum invoice_api_RentPlanEnum {
  RENT_PLAN_ESSENTIAL = "RENT_PLAN_ESSENTIAL",
  RENT_PLAN_LEGACY = "RENT_PLAN_LEGACY",
  RENT_PLAN_MANUAL = "RENT_PLAN_MANUAL",
  RENT_PLAN_SECURED = "RENT_PLAN_SECURED",
  RENT_PLAN_UNKNOWN = "RENT_PLAN_UNKNOWN",
}

export enum invoice_api_ReportFormatEnum {
  BACS = "BACS",
  CSV = "CSV",
  UNKNOWN_REPORT_FORMAT = "UNKNOWN_REPORT_FORMAT",
}

export enum invoice_api_ReportTypeEnum {
  ADD_ON_PRODUCTS = "ADD_ON_PRODUCTS",
  INSURANCE_PRODUCTS = "INSURANCE_PRODUCTS",
  UNKNOWN_REPORT_TYPE = "UNKNOWN_REPORT_TYPE",
}

export enum invoice_api_TransactionMatchEnum {
  TRANSACTION_MATCH_AMOUNT = "TRANSACTION_MATCH_AMOUNT",
  TRANSACTION_MATCH_AMOUNT_APPROXIMATE = "TRANSACTION_MATCH_AMOUNT_APPROXIMATE",
  TRANSACTION_MATCH_DESCRIPTION = "TRANSACTION_MATCH_DESCRIPTION",
  TRANSACTION_MATCH_IVR = "TRANSACTION_MATCH_IVR",
  TRANSACTION_MATCH_MANUAL = "TRANSACTION_MATCH_MANUAL",
  UNKNOWN_TRANSACTION_MATCH = "UNKNOWN_TRANSACTION_MATCH",
}

export enum invoice_api_TransactionReconciliationEnum {
  TRANSACTION_RECONCILIATION_FULL = "TRANSACTION_RECONCILIATION_FULL",
  TRANSACTION_RECONCILIATION_PARTIAL = "TRANSACTION_RECONCILIATION_PARTIAL",
  UNKNOWN_TRANSACTION_RECONCILIATION = "UNKNOWN_TRANSACTION_RECONCILIATION",
}

export enum invoice_api_TransactionStatusEnum {
  TRANSACTION_STATUS_FAILURE_CANCELED = "TRANSACTION_STATUS_FAILURE_CANCELED",
  TRANSACTION_STATUS_FAILURE_DECLINED = "TRANSACTION_STATUS_FAILURE_DECLINED",
  TRANSACTION_STATUS_FAILURE_EXPIRED = "TRANSACTION_STATUS_FAILURE_EXPIRED",
  TRANSACTION_STATUS_FAILURE_GENERIC = "TRANSACTION_STATUS_FAILURE_GENERIC",
  TRANSACTION_STATUS_FAILURE_INSUFFICIENT_FUNDS = "TRANSACTION_STATUS_FAILURE_INSUFFICIENT_FUNDS",
  TRANSACTION_STATUS_FAILURE_INVALID_AMOUNT = "TRANSACTION_STATUS_FAILURE_INVALID_AMOUNT",
  TRANSACTION_STATUS_FAILURE_INVALID_CURRENCY = "TRANSACTION_STATUS_FAILURE_INVALID_CURRENCY",
  TRANSACTION_STATUS_FAILURE_INVALID_QUOTE = "TRANSACTION_STATUS_FAILURE_INVALID_QUOTE",
  TRANSACTION_STATUS_FAILURE_PERMISSION_DENIED = "TRANSACTION_STATUS_FAILURE_PERMISSION_DENIED",
  TRANSACTION_STATUS_FAILURE_QUOTE_EXPIRED = "TRANSACTION_STATUS_FAILURE_QUOTE_EXPIRED",
  TRANSACTION_STATUS_INITIATED = "TRANSACTION_STATUS_INITIATED",
  TRANSACTION_STATUS_INVALID_STATUS = "TRANSACTION_STATUS_INVALID_STATUS",
  TRANSACTION_STATUS_PENDING = "TRANSACTION_STATUS_PENDING",
  TRANSACTION_STATUS_PENDING_EXTERNAL_AUTHORIZATION = "TRANSACTION_STATUS_PENDING_EXTERNAL_AUTHORIZATION",
  TRANSACTION_STATUS_PROCESSING = "TRANSACTION_STATUS_PROCESSING",
  TRANSACTION_STATUS_SENT = "TRANSACTION_STATUS_SENT",
  TRANSACTION_STATUS_STATUS_NOT_AVAILABLE = "TRANSACTION_STATUS_STATUS_NOT_AVAILABLE",
  TRANSACTION_STATUS_SUCCESS = "TRANSACTION_STATUS_SUCCESS",
}

export enum invoice_api_TransactionTypeEnum {
  TRANSACTION_TYPE_CREDIT = "TRANSACTION_TYPE_CREDIT",
  TRANSACTION_TYPE_DEBIT = "TRANSACTION_TYPE_DEBIT",
  UNKNOWN_TRANSACTION_TYPE = "UNKNOWN_TRANSACTION_TYPE",
}

export enum listing_api_VersionStatusEnum {
  VERSION_ARCHIVE = "VERSION_ARCHIVE",
  VERSION_DRAFT = "VERSION_DRAFT",
  VERSION_LET = "VERSION_LET",
  VERSION_RENT_COLLECTION = "VERSION_RENT_COLLECTION",
  VERSION_STATUS_UNKNOWN = "VERSION_STATUS_UNKNOWN",
  VERSION_VERIFIED = "VERSION_VERIFIED",
  VERSION_VERIFYING = "VERSION_VERIFYING",
  VERSION_WITHDRAWN = "VERSION_WITHDRAWN",
}

export enum maintenance_api_MaintenancePlaceEnum {
  ATTIC = "ATTIC",
  BASEMENT = "BASEMENT",
  BATHROOM = "BATHROOM",
  BEDROOM = "BEDROOM",
  GARAGE = "GARAGE",
  HALLWAY = "HALLWAY",
  KITCHEN = "KITCHEN",
  MAINTENANCE_PLACE_UNKNOWN = "MAINTENANCE_PLACE_UNKNOWN",
  OUTDOOR = "OUTDOOR",
  RECEPTION_ROOM = "RECEPTION_ROOM",
}

export enum maintenance_api_MaintenanceResolutionEnum {
  MAINTENANCE_RESOLUTION_UNKNOWN = "MAINTENANCE_RESOLUTION_UNKNOWN",
  TO_BEE_DISCUSSED = "TO_BEE_DISCUSSED",
  TO_BE_DISCUSSED = "TO_BE_DISCUSSED",
  TO_BE_REPLACED = "TO_BE_REPLACED",
}

export enum maintenance_api_MaintenanceStatusEnum {
  IN_WORKS = "IN_WORKS",
  MAINTENANCE_STATUS_UNKNOWN = "MAINTENANCE_STATUS_UNKNOWN",
  NOT_ADDRESSED = "NOT_ADDRESSED",
  REPAIRED = "REPAIRED",
}

export enum messaging_api_PresenceEnum {
  PRESENCE_AWAY = "PRESENCE_AWAY",
  PRESENCE_INVISIBLE = "PRESENCE_INVISIBLE",
  PRESENCE_OFFLINE = "PRESENCE_OFFLINE",
  PRESENCE_ONLINE = "PRESENCE_ONLINE",
  UNKNOWN_PRESENCE = "UNKNOWN_PRESENCE",
}

export enum mortgage_api_ApplicantTypeEnum {
  APPLICANT_TYPE_FIRST_TIME_BUYER = "APPLICANT_TYPE_FIRST_TIME_BUYER",
  APPLICANT_TYPE_UNKNOWN = "APPLICANT_TYPE_UNKNOWN",
}

export enum mortgage_api_MortgageLeadStageEnum {
  AIP = "AIP",
  BOOKED = "BOOKED",
  FIRST_APPOINTMENT = "FIRST_APPOINTMENT",
  INACTIVE = "INACTIVE",
  PRE_CALL_COMPLETED = "PRE_CALL_COMPLETED",
  PRE_CALL_UNSUCCESSFUL = "PRE_CALL_UNSUCCESSFUL",
  SIGN_UP = "SIGN_UP",
}

export enum mortgage_api_MortgageTypeEnum {
  MORTGAGE_TYPE_BUY_TO_LET = "MORTGAGE_TYPE_BUY_TO_LET",
  MORTGAGE_TYPE_STANDARD = "MORTGAGE_TYPE_STANDARD",
  MORTGAGE_TYPE_STANDARD_OR_SHARED_EQUITY = "MORTGAGE_TYPE_STANDARD_OR_SHARED_EQUITY",
  MORTGAGE_TYPE_UNKNOWN = "MORTGAGE_TYPE_UNKNOWN",
}

export enum mortgage_api_ReasonForMortgageEnum {
  REASON_FOR_MORTGAGE_PURCHASE = "REASON_FOR_MORTGAGE_PURCHASE",
  REASON_FOR_MORTGAGE_REMORTGAGE = "REASON_FOR_MORTGAGE_REMORTGAGE",
  REASON_FOR_MORTGAGE_UNKNOWN = "REASON_FOR_MORTGAGE_UNKNOWN",
}

export enum mortgage_api_RelationshipStatusEnum {
  IN_CIVIL_UNION = "IN_CIVIL_UNION",
  MARRIED = "MARRIED",
  RELATIONSHIP_STATUS_UNKNOWN = "RELATIONSHIP_STATUS_UNKNOWN",
  SINGLE = "SINGLE",
  WINDOWED = "WINDOWED",
}

export enum notes_api_CorrelationTypeEnum {
  CORRELATION_TYPE_LISTING = "CORRELATION_TYPE_LISTING",
  CORRELATION_TYPE_MORTGAGE = "CORRELATION_TYPE_MORTGAGE",
  CORRELATION_TYPE_PROPERTY = "CORRELATION_TYPE_PROPERTY",
  CORRELATION_TYPE_UNKNOWN = "CORRELATION_TYPE_UNKNOWN",
  CORRELATION_TYPE_USER = "CORRELATION_TYPE_USER",
}

export enum notes_api_StatusEnum {
  STATUS_ACTIVE = "STATUS_ACTIVE",
  STATUS_DISABLED = "STATUS_DISABLED",
  STATUS_UNKNOWN = "STATUS_UNKNOWN",
}

export enum notification_api_ActionEnum {
  ACTION_ACCEPT = "ACTION_ACCEPT",
  ACTION_CANCEL = "ACTION_CANCEL",
  ACTION_COMPLETE = "ACTION_COMPLETE",
  ACTION_CREATE = "ACTION_CREATE",
  ACTION_EXPIRE = "ACTION_EXPIRE",
  ACTION_OWNERSIP_VERIFICATION_CREATE = "ACTION_OWNERSIP_VERIFICATION_CREATE",
  ACTION_OWNERSIP_VERIFICATION_RESULT = "ACTION_OWNERSIP_VERIFICATION_RESULT",
  ACTION_PAYMENT_LINK = "ACTION_PAYMENT_LINK",
  ACTION_PAYMENT_OVERDUE_LANDLORD = "ACTION_PAYMENT_OVERDUE_LANDLORD",
  ACTION_PAYMENT_OVERDUE_TENANT = "ACTION_PAYMENT_OVERDUE_TENANT",
  ACTION_PENDING = "ACTION_PENDING",
  ACTION_REJECT = "ACTION_REJECT",
  ACTION_REMIND = "ACTION_REMIND",
  ACTION_REMOTE_SALE_NEW_ACCOUNT = "ACTION_REMOTE_SALE_NEW_ACCOUNT",
  ACTION_REMOVE = "ACTION_REMOVE",
  ACTION_RTO_SETUP_COMPLETE = "ACTION_RTO_SETUP_COMPLETE",
  ACTION_RTO_STATE_ACTIVE_ESSENTIAL = "ACTION_RTO_STATE_ACTIVE_ESSENTIAL",
  ACTION_RTO_STATE_ACTIVE_SECURED = "ACTION_RTO_STATE_ACTIVE_SECURED",
  ACTION_RTO_STATE_WAITING_FOR_STANDING_ORDER_SETUP = "ACTION_RTO_STATE_WAITING_FOR_STANDING_ORDER_SETUP",
  ACTION_RTO_TWO_MONTHS_BEFORE_END_OF_TENANCY_DATE = "ACTION_RTO_TWO_MONTHS_BEFORE_END_OF_TENANCY_DATE",
  ACTION_UNKNOWN = "ACTION_UNKNOWN",
  ACTION_UPDATE = "ACTION_UPDATE",
  ACTION_VERIFY = "ACTION_VERIFY",
}

export enum notification_api_CategoryEnum {
  CATEGORY_ACCOUNT = "CATEGORY_ACCOUNT",
  CATEGORY_COMPLIANCE_PRODUCT = "CATEGORY_COMPLIANCE_PRODUCT",
  CATEGORY_CONTRACTS = "CATEGORY_CONTRACTS",
  CATEGORY_DOCUMENT = "CATEGORY_DOCUMENT",
  CATEGORY_END_OF_TENANCY = "CATEGORY_END_OF_TENANCY",
  CATEGORY_HOLDING_DEPOSIT = "CATEGORY_HOLDING_DEPOSIT",
  CATEGORY_INSURANCES = "CATEGORY_INSURANCES",
  CATEGORY_INVITATION = "CATEGORY_INVITATION",
  CATEGORY_LISTING = "CATEGORY_LISTING",
  CATEGORY_MAINTENANCE = "CATEGORY_MAINTENANCE",
  CATEGORY_OFFER = "CATEGORY_OFFER",
  CATEGORY_PAYMENT = "CATEGORY_PAYMENT",
  CATEGORY_REFERENCING = "CATEGORY_REFERENCING",
  CATEGORY_RENEWAL = "CATEGORY_RENEWAL",
  CATEGORY_UNKNOWN = "CATEGORY_UNKNOWN",
  CATEGORY_VIEWING = "CATEGORY_VIEWING",
}

export enum notification_api_EntityEnum {
  ENTITY_DOCUMENT = "ENTITY_DOCUMENT",
  ENTITY_DOCUMENT_EXPIRATION = "ENTITY_DOCUMENT_EXPIRATION",
  ENTITY_INSURANCE_CI_PAYMENT = "ENTITY_INSURANCE_CI_PAYMENT",
  ENTITY_INSURANCE_HEI_PAYMENT = "ENTITY_INSURANCE_HEI_PAYMENT",
  ENTITY_INSURANCE_RGI_PAYMENT = "ENTITY_INSURANCE_RGI_PAYMENT",
  ENTITY_MESSAGE = "ENTITY_MESSAGE",
  ENTITY_MORTGAGE_NOTE = "ENTITY_MORTGAGE_NOTE",
  ENTITY_OFFER = "ENTITY_OFFER",
  ENTITY_OFFER_CLAUSE = "ENTITY_OFFER_CLAUSE",
  ENTITY_OFFER_DEPOSITED = "ENTITY_OFFER_DEPOSITED",
  ENTITY_OFFER_DEPOSIT_REPLACEMENT = "ENTITY_OFFER_DEPOSIT_REPLACEMENT",
  ENTITY_OFFER_EXTRA_PAYMENT = "ENTITY_OFFER_EXTRA_PAYMENT",
  ENTITY_OFFER_HOLDING_DEPOSIT_PAYMENT = "ENTITY_OFFER_HOLDING_DEPOSIT_PAYMENT",
  ENTITY_OFFER_OTHER = "ENTITY_OFFER_OTHER",
  ENTITY_OFFER_REFERENCE_CHECKS = "ENTITY_OFFER_REFERENCE_CHECKS",
  ENTITY_OFFER_RENEWAL = "ENTITY_OFFER_RENEWAL",
  ENTITY_OFFER_RENT_ADVANCE = "ENTITY_OFFER_RENT_ADVANCE",
  ENTITY_OFFER_RENT_COLLECTION = "ENTITY_OFFER_RENT_COLLECTION",
  ENTITY_OFFER_RENT_COLLECTION_ESSENTIAL = "ENTITY_OFFER_RENT_COLLECTION_ESSENTIAL",
  ENTITY_OFFER_RENT_COLLECTION_MANUAL = "ENTITY_OFFER_RENT_COLLECTION_MANUAL",
  ENTITY_OFFER_RENT_COLLECTION_NOT_MANUAL = "ENTITY_OFFER_RENT_COLLECTION_NOT_MANUAL",
  ENTITY_OFFER_RENT_COLLECTION_SECURED = "ENTITY_OFFER_RENT_COLLECTION_SECURED",
  ENTITY_OFFER_RENT_PAYMENT = "ENTITY_OFFER_RENT_PAYMENT",
  ENTITY_OFFER_RTO = "ENTITY_OFFER_RTO",
  ENTITY_OFFER_SIGNATURE = "ENTITY_OFFER_SIGNATURE",
  ENTITY_OFFER_SIGNATURE_INVITATION = "ENTITY_OFFER_SIGNATURE_INVITATION",
  ENTITY_PAYMENT = "ENTITY_PAYMENT",
  ENTITY_PAYMENT_INVOICE = "ENTITY_PAYMENT_INVOICE",
  ENTITY_PAYMENT_MANDATE = "ENTITY_PAYMENT_MANDATE",
  ENTITY_PAYMENT_OVERDUE = "ENTITY_PAYMENT_OVERDUE",
  ENTITY_PAYMENT_TRANSACTION = "ENTITY_PAYMENT_TRANSACTION",
  ENTITY_PRODUCT_MUUV = "ENTITY_PRODUCT_MUUV",
  ENTITY_PROPERTY = "ENTITY_PROPERTY",
  ENTITY_PROPERTY_BANKDETAILS = "ENTITY_PROPERTY_BANKDETAILS",
  ENTITY_PROPERTY_INVITATION = "ENTITY_PROPERTY_INVITATION",
  ENTITY_PROPERTY_INVITATION_MANAGER = "ENTITY_PROPERTY_INVITATION_MANAGER",
  ENTITY_PROPERTY_INVITATION_MANAGER_UNLISTED = "ENTITY_PROPERTY_INVITATION_MANAGER_UNLISTED",
  ENTITY_PROPERTY_INVITATION_REFER_LANDLORD = "ENTITY_PROPERTY_INVITATION_REFER_LANDLORD",
  ENTITY_PROPERTY_ISSUE = "ENTITY_PROPERTY_ISSUE",
  ENTITY_PROPERTY_ISSUE_ASSIGN_CONTRACTOR = "ENTITY_PROPERTY_ISSUE_ASSIGN_CONTRACTOR",
  ENTITY_PROPERTY_ISSUE_ASSIGN_LOCALHEROES = "ENTITY_PROPERTY_ISSUE_ASSIGN_LOCALHEROES",
  ENTITY_PROPERTY_LISTING = "ENTITY_PROPERTY_LISTING",
  ENTITY_PROPERTY_LISTING_AMENDMENT = "ENTITY_PROPERTY_LISTING_AMENDMENT",
  ENTITY_PROPERTY_LISTING_PAYMENT = "ENTITY_PROPERTY_LISTING_PAYMENT",
  ENTITY_PROPERTY_OWNERSHIP_VERIF = "ENTITY_PROPERTY_OWNERSHIP_VERIF",
  ENTITY_PROPERTY_PREFACE = "ENTITY_PROPERTY_PREFACE",
  ENTITY_PROPERTY_PUBLICATION = "ENTITY_PROPERTY_PUBLICATION",
  ENTITY_PROPERTY_QUESTION = "ENTITY_PROPERTY_QUESTION",
  ENTITY_PROPERTY_TASK = "ENTITY_PROPERTY_TASK",
  ENTITY_PROPERTY_TENANCY = "ENTITY_PROPERTY_TENANCY",
  ENTITY_PROPERTY_TENANCY_CLAIM = "ENTITY_PROPERTY_TENANCY_CLAIM",
  ENTITY_PROPERTY_TENANCY_CLAIM_DISPUTE = "ENTITY_PROPERTY_TENANCY_CLAIM_DISPUTE",
  ENTITY_PROPERTY_TENANCY_CLAIM_PAYMENT = "ENTITY_PROPERTY_TENANCY_CLAIM_PAYMENT",
  ENTITY_PROPERTY_TENANCY_DEPOSIT = "ENTITY_PROPERTY_TENANCY_DEPOSIT",
  ENTITY_PROPERTY_TENANCY_TERMINATION = "ENTITY_PROPERTY_TENANCY_TERMINATION",
  ENTITY_PROPERTY_VIEWING = "ENTITY_PROPERTY_VIEWING",
  ENTITY_PROPERTY_VIEWING_BROADCASTING = "ENTITY_PROPERTY_VIEWING_BROADCASTING",
  ENTITY_PROPERTY_VIEWING_FAVORITE = "ENTITY_PROPERTY_VIEWING_FAVORITE",
  ENTITY_PROPERTY_VIEWING_LETTINGS = "ENTITY_PROPERTY_VIEWING_LETTINGS",
  ENTITY_PROPERTY_VIEWING_RARITY = "ENTITY_PROPERTY_VIEWING_RARITY",
  ENTITY_PROPERTY_VIEWING_RESCHEDULE = "ENTITY_PROPERTY_VIEWING_RESCHEDULE",
  ENTITY_PROPERTY_VIEWING_REWARD = "ENTITY_PROPERTY_VIEWING_REWARD",
  ENTITY_UNKNOWN = "ENTITY_UNKNOWN",
  ENTITY_USER = "ENTITY_USER",
  ENTITY_USER_BANKDETAILS = "ENTITY_USER_BANKDETAILS",
  ENTITY_USER_EMAIL = "ENTITY_USER_EMAIL",
  ENTITY_USER_PASSWORD = "ENTITY_USER_PASSWORD",
  ENTITY_USER_PHONE = "ENTITY_USER_PHONE",
  ENTITY_USER_SIGNIN = "ENTITY_USER_SIGNIN",
}

export enum notification_api_EventChannelEnum {
  EVENT_CHANNEL_EMAIL = "EVENT_CHANNEL_EMAIL",
  EVENT_CHANNEL_EMAILV2 = "EVENT_CHANNEL_EMAILV2",
  EVENT_CHANNEL_PLATFORM = "EVENT_CHANNEL_PLATFORM",
  EVENT_CHANNEL_SLACK = "EVENT_CHANNEL_SLACK",
  EVENT_CHANNEL_SMS = "EVENT_CHANNEL_SMS",
  EVENT_CHANNEL_WHATSAPP = "EVENT_CHANNEL_WHATSAPP",
  UNKNOWN_EVENT_CHANNEL = "UNKNOWN_EVENT_CHANNEL",
}

export enum notification_api_RoleEnum {
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_AUTHOR = "ROLE_AUTHOR",
  ROLE_GUARANTOR = "ROLE_GUARANTOR",
  ROLE_LANDLORD = "ROLE_LANDLORD",
  ROLE_MANAGER = "ROLE_MANAGER",
  ROLE_TENANT = "ROLE_TENANT",
  ROLE_UNKNOWN = "ROLE_UNKNOWN",
}

export enum offer_api_AdvanceStateEnum {
  ADVANCE_CANCELLED = "ADVANCE_CANCELLED",
  ADVANCE_DENIED = "ADVANCE_DENIED",
  ADVANCE_GRANTED = "ADVANCE_GRANTED",
  ADVANCE_REQUESTED = "ADVANCE_REQUESTED",
  UNKNOWN_ADVANCE_STATE = "UNKNOWN_ADVANCE_STATE",
}

export enum offer_api_ClauseStateEnum {
  CLAUSE_ACCEPTED = "CLAUSE_ACCEPTED",
  CLAUSE_DECLINED = "CLAUSE_DECLINED",
  CLAUSE_PENDING = "CLAUSE_PENDING",
}

export enum offer_api_DepositTypeEnum {
  DEPOSIT_REPLACEMENT = "DEPOSIT_REPLACEMENT",
  DEPOSIT_TRADITIONAL_5WEEK = "DEPOSIT_TRADITIONAL_5WEEK",
  UNKNOWN_DEPOSIT_TYPE = "UNKNOWN_DEPOSIT_TYPE",
}

export enum offer_api_EmploymentStatusEnum {
  EMPLOYED = "EMPLOYED",
  RETIRED = "RETIRED",
  STUDENT = "STUDENT",
  UNEMPLOYED = "UNEMPLOYED",
  UNKNOWN_EMPLOYMENT_STATUS = "UNKNOWN_EMPLOYMENT_STATUS",
}

export enum offer_api_OfferActionEnum {
  DEPOSIT_TYPE_CHANGED = "DEPOSIT_TYPE_CHANGED",
  FIRST_PAYMENT_DATE_CHANGED = "FIRST_PAYMENT_DATE_CHANGED",
  GUARANTOR_ADD = "GUARANTOR_ADD",
  GUARANTOR_ADDRESS_CHANGED = "GUARANTOR_ADDRESS_CHANGED",
  GUARANTOR_EMAIL_CHANGED = "GUARANTOR_EMAIL_CHANGED",
  GUARANTOR_NAME_CHANGED = "GUARANTOR_NAME_CHANGED",
  LANDLORD_NAME_CHANGED = "LANDLORD_NAME_CHANGED",
  MOVE_IN_DATE_CHANGED = "MOVE_IN_DATE_CHANGED",
  PAYMENT_FREQ_CHANGED = "PAYMENT_FREQ_CHANGED",
  SECOND_LANDLORD_ADD = "SECOND_LANDLORD_ADD",
  TENANCY_LENGTH_CHANGED = "TENANCY_LENGTH_CHANGED",
  TENANT_ADD = "TENANT_ADD",
  TENANT_ADDRESS_CHANGED = "TENANT_ADDRESS_CHANGED",
  TENANT_EMAIL_CHANGED = "TENANT_EMAIL_CHANGED",
  TENANT_NAME_CHANGED = "TENANT_NAME_CHANGED",
}

export enum offer_api_OfferErrorEnum {
  ALREADY_PUBLISHED = "ALREADY_PUBLISHED",
  CLAUSES_IN_PENDING = "CLAUSES_IN_PENDING",
  DOCUMENTS_VERIFICATION_ERROR = "DOCUMENTS_VERIFICATION_ERROR",
  DOCUMENT_REQUIRED = "DOCUMENT_REQUIRED",
  EMPTY_VALUE = "EMPTY_VALUE",
  INTERVAL_NOT_AVAILABLE = "INTERVAL_NOT_AVAILABLE",
  INVALID_MAX_VALUE = "INVALID_MAX_VALUE",
  INVALID_MIN_VALUE = "INVALID_MIN_VALUE",
  INVALID_OFFER_STATUS = "INVALID_OFFER_STATUS",
  INVALID_RATING = "INVALID_RATING",
  INVALID_SUM = "INVALID_SUM",
  MAX_LESS_MIN = "MAX_LESS_MIN",
  NOT_READY_FOR_PUBLICATION = "NOT_READY_FOR_PUBLICATION",
  NO_SUCH_CLAUSE = "NO_SUCH_CLAUSE",
  NO_UPDATES = "NO_UPDATES",
  OFFER_ALREADY_DELETED = "OFFER_ALREADY_DELETED",
  OFFER_LOCKED = "OFFER_LOCKED",
  OFFER_NOT_DELETED = "OFFER_NOT_DELETED",
  OFFER_NOT_EXISTS = "OFFER_NOT_EXISTS",
  PAYMENT_REQUIRED = "PAYMENT_REQUIRED",
  PROPERTY_EMPTY = "PROPERTY_EMPTY",
  UNKNOWN_OFFER_ERROR = "UNKNOWN_OFFER_ERROR",
  UNKNOWN_VALUE = "UNKNOWN_VALUE",
  USER_NOT_EXISTS = "USER_NOT_EXISTS",
  USER_NOT_OWNS = "USER_NOT_OWNS",
  USER_NO_ACCESS = "USER_NO_ACCESS",
  WRONG_CURRENT_OFFER_STATUS = "WRONG_CURRENT_OFFER_STATUS",
  ZERO_VALUE = "ZERO_VALUE",
}

export enum offer_api_OfferStateEnum {
  ACCEPTED = "ACCEPTED",
  ACTIVE = "ACTIVE",
  AWAITING_APPLICANT_CHECK_PAYMENT = "AWAITING_APPLICANT_CHECK_PAYMENT",
  AWAITING_APPROVE = "AWAITING_APPROVE",
  AWAITING_CHECKS = "AWAITING_CHECKS",
  AWAITING_CONFIRM = "AWAITING_CONFIRM",
  AWAITING_CONFIRM_EXTRAS = "AWAITING_CONFIRM_EXTRAS",
  AWAITING_CONFIRM_RENT = "AWAITING_CONFIRM_RENT",
  AWAITING_DOCUMENTS = "AWAITING_DOCUMENTS",
  AWAITING_FEES = "AWAITING_FEES",
  AWAITING_GUARANTOR = "AWAITING_GUARANTOR",
  AWAITING_RENEW = "AWAITING_RENEW",
  AWAITING_TENANCY = "AWAITING_TENANCY",
  CANCELED = "CANCELED",
  CHECKING = "CHECKING",
  CONTRACT_PREPARATION = "CONTRACT_PREPARATION",
  CONTRACT_REVIEW_AND_CLAUSES = "CONTRACT_REVIEW_AND_CLAUSES",
  DENIED = "DENIED",
  DEPOSIT_CONFIRMED = "DEPOSIT_CONFIRMED",
  EARLY_TERMINATION = "EARLY_TERMINATION",
  ERROR = "ERROR",
  EXPIRED = "EXPIRED",
  EXTRAS_CONFIRMED = "EXTRAS_CONFIRMED",
  FINISHED = "FINISHED",
  HOLDING_DEPOSIT = "HOLDING_DEPOSIT",
  MANUAL_COLLECTING = "MANUAL_COLLECTING",
  PENDING = "PENDING",
  RENTPAID = "RENTPAID",
  RTO_STATE_ACTIVE = "RTO_STATE_ACTIVE",
  RTO_STATE_ACTIVE_ROLLING_TENANCY = "RTO_STATE_ACTIVE_ROLLING_TENANCY",
  RTO_STATE_CANCELLED = "RTO_STATE_CANCELLED",
  RTO_STATE_ENDING = "RTO_STATE_ENDING",
  RTO_STATE_FINISHED = "RTO_STATE_FINISHED",
  RTO_STATE_PENDING_SETUP = "RTO_STATE_PENDING_SETUP",
  RTO_STATE_WAITING_FOR_PAYMENT = "RTO_STATE_WAITING_FOR_PAYMENT",
  RTO_STATE_WAITING_FOR_STANDING_ORDER_SETUP = "RTO_STATE_WAITING_FOR_STANDING_ORDER_SETUP",
  RTO_STATE_WAITING_FOR_TENANT_CONFIRMATION = "RTO_STATE_WAITING_FOR_TENANT_CONFIRMATION",
  SIGNED = "SIGNED",
  SIGNING = "SIGNING",
  TERMINATED = "TERMINATED",
  UNKNOWN_OFFER_STATE = "UNKNOWN_OFFER_STATE",
}

export enum offer_api_OfferStatusEnum {
  INVALID = "INVALID",
  SUCCESS = "SUCCESS",
  UNKNOWN_OFFER_STATUS = "UNKNOWN_OFFER_STATUS",
}

export enum offer_api_PaymentStatusEnum {
  PAYMENT_CONFIRM = "PAYMENT_CONFIRM",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",
  UNKNOWN_PAYMENT_STATUS = "UNKNOWN_PAYMENT_STATUS",
}

export enum offer_api_PayoutStateEnum {
  PAYOUT_CONFIRMED = "PAYOUT_CONFIRMED",
  PAYOUT_PAID = "PAYOUT_PAID",
  PAYOUT_PENDING = "PAYOUT_PENDING",
  UNKNOWN_PAYOUT_STATE = "UNKNOWN_PAYOUT_STATE",
}

export enum offer_api_RentPlanEnum {
  RENT_PLAN_ESSENTIAL = "RENT_PLAN_ESSENTIAL",
  RENT_PLAN_LEGACY = "RENT_PLAN_LEGACY",
  RENT_PLAN_MANUAL = "RENT_PLAN_MANUAL",
  RENT_PLAN_SECURED = "RENT_PLAN_SECURED",
  RENT_PLAN_UNKNOWN = "RENT_PLAN_UNKNOWN",
}

export enum offer_api_SignStatusStateEnum {
  AWAITING_SIGNATURE = "AWAITING_SIGNATURE",
  SIGNATURE_PROVIDED = "SIGNATURE_PROVIDED",
  SIGNATURE_REFUSED = "SIGNATURE_REFUSED",
  UNKNOWN_SIGN_STATUS_STATE = "UNKNOWN_SIGN_STATUS_STATE",
}

export enum offer_api_TenantRiskEnum {
  HIGH_RISK = "HIGH_RISK",
  LOW_RISK = "LOW_RISK",
  MEDIUM_RISK = "MEDIUM_RISK",
  PENDING_RISK = "PENDING_RISK",
  UNKNOWN_TENANT_RISK = "UNKNOWN_TENANT_RISK",
}

export enum offer_api_rto_RentPlanEnum {
  RENT_PLAN_ESSENTIAL = "RENT_PLAN_ESSENTIAL",
  RENT_PLAN_SECURED = "RENT_PLAN_SECURED",
  RENT_PLAN_UNKNOWN = "RENT_PLAN_UNKNOWN",
}

export enum offer_api_rto_RtoStateEnum {
  RTO_STATE_ACTIVE = "RTO_STATE_ACTIVE",
  RTO_STATE_ACTIVE_ROLLING_TENANCY = "RTO_STATE_ACTIVE_ROLLING_TENANCY",
  RTO_STATE_CANCELLED = "RTO_STATE_CANCELLED",
  RTO_STATE_ENDING = "RTO_STATE_ENDING",
  RTO_STATE_FINISHED = "RTO_STATE_FINISHED",
  RTO_STATE_PENDING_SETUP = "RTO_STATE_PENDING_SETUP",
  RTO_STATE_UNKNOWN = "RTO_STATE_UNKNOWN",
  RTO_STATE_WAITING_FOR_PAYMENT = "RTO_STATE_WAITING_FOR_PAYMENT",
  RTO_STATE_WAITING_FOR_STANDING_ORDER_SETUP = "RTO_STATE_WAITING_FOR_STANDING_ORDER_SETUP",
  RTO_STATE_WAITING_FOR_TENANT_CONFIRMATION = "RTO_STATE_WAITING_FOR_TENANT_CONFIRMATION",
}

export enum property_api_PropertyBillTypeEnum {
  PROPERTY_BILL_ELECTRICITY = "PROPERTY_BILL_ELECTRICITY",
  PROPERTY_BILL_GAS = "PROPERTY_BILL_GAS",
  PROPERTY_BILL_INTERNET = "PROPERTY_BILL_INTERNET",
  PROPERTY_BILL_SATCABLE_TV = "PROPERTY_BILL_SATCABLE_TV",
  PROPERTY_BILL_TELEPHONE = "PROPERTY_BILL_TELEPHONE",
  PROPERTY_BILL_TV_LICENSE = "PROPERTY_BILL_TV_LICENSE",
  PROPERTY_BILL_WATER = "PROPERTY_BILL_WATER",
  PROPERTY_COUNCIL_TAX = "PROPERTY_COUNCIL_TAX",
  UNKNOWN_PROPERTY_BILL_TYPE = "UNKNOWN_PROPERTY_BILL_TYPE",
}

export enum property_api_PropertyCompleteEnum {
  PROPERTY_COMPLETE_DOCUMENTS = "PROPERTY_COMPLETE_DOCUMENTS",
  PROPERTY_COMPLETE_EMPTY = "PROPERTY_COMPLETE_EMPTY",
  PROPERTY_COMPLETE_FEATURES = "PROPERTY_COMPLETE_FEATURES",
  PROPERTY_COMPLETE_LANDLORD = "PROPERTY_COMPLETE_LANDLORD",
  PROPERTY_COMPLETE_LISTABLE = "PROPERTY_COMPLETE_LISTABLE",
  PROPERTY_COMPLETE_LOCATION = "PROPERTY_COMPLETE_LOCATION",
  PROPERTY_COMPLETE_MANAGEMENT = "PROPERTY_COMPLETE_MANAGEMENT",
  PROPERTY_COMPLETE_MEDIA = "PROPERTY_COMPLETE_MEDIA",
  PROPERTY_COMPLETE_PAYMENT = "PROPERTY_COMPLETE_PAYMENT",
  PROPERTY_COMPLETE_POSTCODE = "PROPERTY_COMPLETE_POSTCODE",
  PROPERTY_COMPLETE_POST_DETAILS = "PROPERTY_COMPLETE_POST_DETAILS",
  PROPERTY_COMPLETE_RENTAL_DETAILS = "PROPERTY_COMPLETE_RENTAL_DETAILS",
  PROPERTY_COMPLETE_ROOMS = "PROPERTY_COMPLETE_ROOMS",
  UNKNOWN_PROPERTY_COMPLETE = "UNKNOWN_PROPERTY_COMPLETE",
}

export enum property_api_PropertyErrorEnum {
  ALREADY_PUBLISHED = "ALREADY_PUBLISHED",
  DOCUMENTS_VERIFICATION_ERROR = "DOCUMENTS_VERIFICATION_ERROR",
  DOCUMENT_REQUIRED = "DOCUMENT_REQUIRED",
  EMPTY_VALUE = "EMPTY_VALUE",
  INTERVAL_NOT_AVAILABLE = "INTERVAL_NOT_AVAILABLE",
  INVALID_MAX_VALUE = "INVALID_MAX_VALUE",
  INVALID_MIN_VALUE = "INVALID_MIN_VALUE",
  INVALID_RATING = "INVALID_RATING",
  INVALID_SUM = "INVALID_SUM",
  MAX_LESS_MIN = "MAX_LESS_MIN",
  NOT_READY_FOR_PUBLICATION = "NOT_READY_FOR_PUBLICATION",
  PAYMENT_REQUIRED = "PAYMENT_REQUIRED",
  PROPERTY_ALREADY_DELETED = "PROPERTY_ALREADY_DELETED",
  PROPERTY_LOCKED = "PROPERTY_LOCKED",
  PROPERTY_NOT_DELETED = "PROPERTY_NOT_DELETED",
  PROPERTY_NOT_EXISTS = "PROPERTY_NOT_EXISTS",
  PROPERTY_RENTED = "PROPERTY_RENTED",
  UNKNOWN_PROPERTY_ERROR = "UNKNOWN_PROPERTY_ERROR",
  UNKNOWN_VALUE = "UNKNOWN_VALUE",
  USER_NOT_EXISTS = "USER_NOT_EXISTS",
  USER_NOT_OWNS = "USER_NOT_OWNS",
  ZERO_VALUE = "ZERO_VALUE",
}

export enum property_api_PropertyFurnitureEnum {
  PROPERTY_FURNITURE_FURNISHED = "PROPERTY_FURNITURE_FURNISHED",
  PROPERTY_FURNITURE_PARTLY_FURNISHED = "PROPERTY_FURNITURE_PARTLY_FURNISHED",
  PROPERTY_FURNITURE_UNFURNISHED = "PROPERTY_FURNITURE_UNFURNISHED",
  UNKNOWN_PROPERTY_FURNITURE = "UNKNOWN_PROPERTY_FURNITURE",
}

export enum property_api_PropertyOutsideSpaceEnum {
  PROPERTY_OUTSIDE_SPACE_BALCONY = "PROPERTY_OUTSIDE_SPACE_BALCONY",
  PROPERTY_OUTSIDE_SPACE_COMMUNAL_GARDEN = "PROPERTY_OUTSIDE_SPACE_COMMUNAL_GARDEN",
  PROPERTY_OUTSIDE_SPACE_NONE = "PROPERTY_OUTSIDE_SPACE_NONE",
  PROPERTY_OUTSIDE_SPACE_PRIVATE_GARDEN = "PROPERTY_OUTSIDE_SPACE_PRIVATE_GARDEN",
  PROPERTY_OUTSIDE_SPACE_TERRACE = "PROPERTY_OUTSIDE_SPACE_TERRACE",
  UNKNOWN_PROPERTY_OUTSIDE_SPACE = "UNKNOWN_PROPERTY_OUTSIDE_SPACE",
}

export enum property_api_PropertyParkingEnum {
  PROPERTY_PARKING_DOUBLE_GARAGE = "PROPERTY_PARKING_DOUBLE_GARAGE",
  PROPERTY_PARKING_GARAGE = "PROPERTY_PARKING_GARAGE",
  PROPERTY_PARKING_NONE = "PROPERTY_PARKING_NONE",
  PROPERTY_PARKING_OFFSTREET = "PROPERTY_PARKING_OFFSTREET",
  PROPERTY_PARKING_RESIDENTS_PARKING = "PROPERTY_PARKING_RESIDENTS_PARKING",
  UNKNOWN_PROPERTY_PARKING = "UNKNOWN_PROPERTY_PARKING",
}

export enum property_api_PropertyStatusEnum {
  ACTIVE = "ACTIVE",
  AMENDMENT = "AMENDMENT",
  ARCHIVE = "ARCHIVE",
  AWAITING_DEPOSIT = "AWAITING_DEPOSIT",
  INCOMPLETE = "INCOMPLETE",
  LISTED = "LISTED",
  MISSING = "MISSING",
  OFFERING = "OFFERING",
  RENTED = "RENTED",
  RENT_COLLECTION = "RENT_COLLECTION",
  UNKNOWN_STATUS = "UNKNOWN_STATUS",
  VERIFYING = "VERIFYING",
  WITHDRAWN = "WITHDRAWN",
}

export enum property_api_PropertyTypeEnum {
  PROPERTY_TYPE_APARTMENT = "PROPERTY_TYPE_APARTMENT",
  PROPERTY_TYPE_BUNGALOW = "PROPERTY_TYPE_BUNGALOW",
  PROPERTY_TYPE_COTTAGE = "PROPERTY_TYPE_COTTAGE",
  PROPERTY_TYPE_DETACHED = "PROPERTY_TYPE_DETACHED",
  PROPERTY_TYPE_FLAT = "PROPERTY_TYPE_FLAT",
  PROPERTY_TYPE_MAISONETTE = "PROPERTY_TYPE_MAISONETTE",
  PROPERTY_TYPE_OTHER = "PROPERTY_TYPE_OTHER",
  PROPERTY_TYPE_PARK_HOME = "PROPERTY_TYPE_PARK_HOME",
  PROPERTY_TYPE_PENTHOUSE = "PROPERTY_TYPE_PENTHOUSE",
  PROPERTY_TYPE_SEMI_DETACHED = "PROPERTY_TYPE_SEMI_DETACHED",
  PROPERTY_TYPE_STUDIO = "PROPERTY_TYPE_STUDIO",
  PROPERTY_TYPE_TERRACED = "PROPERTY_TYPE_TERRACED",
  PROPERTY_TYPE_TOWN_HOUSE = "PROPERTY_TYPE_TOWN_HOUSE",
  UNKNOWN_PROPERTY_TYPE = "UNKNOWN_PROPERTY_TYPE",
}

export enum property_api_ReorderPhotosErrorEnum {
  PHOTOS_REQUIRED = "PHOTOS_REQUIRED",
  PROPERTY_INVALID = "PROPERTY_INVALID",
  PROPERTY_OR_PHOTOS_LISTED = "PROPERTY_OR_PHOTOS_LISTED",
  PROPERTY_REQUIRED = "PROPERTY_REQUIRED",
  STAGE_REQUIRED = "STAGE_REQUIRED",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

export enum property_api_ReorderPhotosStageEnum {
  REORDER_PHOTOS_DISCARD = "REORDER_PHOTOS_DISCARD",
  REORDER_PHOTOS_EDIT_STATUS = "REORDER_PHOTOS_EDIT_STATUS",
  REORDER_PHOTOS_UPDATE = "REORDER_PHOTOS_UPDATE",
  UNKNOWN_STAGE = "UNKNOWN_STAGE",
}

export enum property_api_TenancyIntentionEnum {
  INTENTION_RENEW = "INTENTION_RENEW",
  INTENTION_TERMINATE = "INTENTION_TERMINATE",
  INTENTION_UNKNOWN = "INTENTION_UNKNOWN",
}

export enum property_api_TenancyStateEnum {
  TENANCY_ACTIVE = "TENANCY_ACTIVE",
  TENANCY_CLOSED = "TENANCY_CLOSED",
  TENANCY_TERMINATING = "TENANCY_TERMINATING",
}

export enum property_api_TenantRiskEnum {
  HIGH_RISK = "HIGH_RISK",
  LOW_RISK = "LOW_RISK",
  MEDIUM_RISK = "MEDIUM_RISK",
  PENDING_RISK = "PENDING_RISK",
  UNKNOWN_TENANT_RISK = "UNKNOWN_TENANT_RISK",
}

export enum rightmove_api_RightmoveErrorEnum {
  ALREADY_PUBLISHED = "ALREADY_PUBLISHED",
  PROPERTY_NOT_EXISTS = "PROPERTY_NOT_EXISTS",
  PROPERTY_NOT_LISTED = "PROPERTY_NOT_LISTED",
  PROPERTY_RENTED = "PROPERTY_RENTED",
  UNKNOWN_RIGHTMOVE_ERROR = "UNKNOWN_RIGHTMOVE_ERROR",
  USER_NOT_FOUND = "USER_NOT_FOUND",
}

export enum viewing_api_ManagerRewardStatusEnum {
  MANAGER_REWARD_STATUS_AWAITING_REWARD_PAYMENT = "MANAGER_REWARD_STATUS_AWAITING_REWARD_PAYMENT",
  MANAGER_REWARD_STATUS_ENTITLED_TO_REWARD = "MANAGER_REWARD_STATUS_ENTITLED_TO_REWARD",
  MANAGER_REWARD_STATUS_NO_REWARD = "MANAGER_REWARD_STATUS_NO_REWARD",
  MANAGER_REWARD_STATUS_REWARD_PAID = "MANAGER_REWARD_STATUS_REWARD_PAID",
  MANAGER_REWARD_STATUS_UNKNOWN = "MANAGER_REWARD_STATUS_UNKNOWN",
}

export enum viewing_api_MoveinDateEnum {
  MOVEIN_DATE_AS_SOON_AS_POSSIBLE = "MOVEIN_DATE_AS_SOON_AS_POSSIBLE",
  MOVEIN_DATE_ONE_TO_TWO_MONTH = "MOVEIN_DATE_ONE_TO_TWO_MONTH",
  MOVEIN_DATE_TWO_PLUS_MONTHS = "MOVEIN_DATE_TWO_PLUS_MONTHS",
  MOVEIN_DATE_TWO_TO_FOUR_WEEKS = "MOVEIN_DATE_TWO_TO_FOUR_WEEKS",
  MOVEIN_DATE_UNKNOWN = "MOVEIN_DATE_UNKNOWN",
}

export enum viewing_api_ViewingStateEnum {
  VIEWING_STATE_ACCEPTED = "VIEWING_STATE_ACCEPTED",
  VIEWING_STATE_CANCELLED = "VIEWING_STATE_CANCELLED",
  VIEWING_STATE_COMPLETED = "VIEWING_STATE_COMPLETED",
  VIEWING_STATE_FAILED = "VIEWING_STATE_FAILED",
  VIEWING_STATE_MANAGER_DECISION = "VIEWING_STATE_MANAGER_DECISION",
  VIEWING_STATE_OFFER = "VIEWING_STATE_OFFER",
  VIEWING_STATE_REJECTED = "VIEWING_STATE_REJECTED",
  VIEWING_STATE_UNKNOWN = "VIEWING_STATE_UNKNOWN",
  VIEWING_STATE_VIEWER_DECISION = "VIEWING_STATE_VIEWER_DECISION",
}

export enum zoopla_api_ZooplaErrorEnum {
  ALREADY_PUBLISHED = "ALREADY_PUBLISHED",
  PROPERTY_NOT_EXISTS = "PROPERTY_NOT_EXISTS",
  PROPERTY_NOT_LISTED = "PROPERTY_NOT_LISTED",
  PROPERTY_RENTED = "PROPERTY_RENTED",
  UNKNOWN_ZOOPLA_ERROR = "UNKNOWN_ZOOPLA_ERROR",
  USER_NOT_FOUND = "USER_NOT_FOUND",
}

export interface _int32_offer_api_ScheduleNotificationInputMap {
  readonly value?: offer_api_ScheduleNotificationInput | null;
  readonly key: number;
}

export interface _string__stringInputMap {
  readonly key: string;
  readonly value?: string | null;
}

export interface _string_google_golang_org_protobuf_types_known_timestamppb_TimestampInputMap {
  readonly key: string;
  readonly value?: string | null;
}

export interface account_api_AccountBankDetailsInput {
  readonly society?: string | null;
  readonly sort_code?: string | null;
  readonly account_number?: string | null;
  readonly account_name?: string | null;
  readonly personal_reference?: string | null;
}

export interface account_api_AccountContactInput {
  readonly contact_status?: account_api_AccountContactStatusInput | null;
  readonly email_primary?: string | null;
  readonly phone_primary?: string | null;
  readonly phones_secondary?: ReadonlyArray<account_api_PhoneInput> | null;
}

export interface account_api_AccountContactStatusInput {
  readonly phone_verified?: boolean | null;
  readonly email_verified?: boolean | null;
}

export interface account_api_AccountDetailsInput {
  readonly uk_ni?: string | null;
  readonly birthday?: string | null;
  readonly avatar?: string | null;
  readonly full_name?: string | null;
  readonly first_name?: string | null;
  readonly last_name?: string | null;
  readonly gender?: account_api_AccountGenderEnum | null;
}

export interface account_api_AccountFavoritesInput {
  readonly properties?: ReadonlyArray<string> | null;
  readonly placeholder?: boolean | null;
}

export interface account_api_AccountInput {
  readonly block?: boolean | null;
  readonly authorization_origin?: account_api_AuthorizationOriginEnum | null;
  readonly resources?: account_api_AccountResourcesInput | null;
  readonly location?: account_api_AccountLocationInput | null;
  readonly viewed_videos_ids?: ReadonlyArray<number> | null;
  readonly generated?: boolean | null;
  readonly deleted?: boolean | null;
  readonly creation_type?: account_api_AccountCreationTypeEnum | null;
  readonly created_at?: string | null;
  readonly test?: boolean | null;
  readonly details?: account_api_AccountDetailsInput | null;
  readonly admin?: boolean | null;
  readonly identity_verification?: boolean | null;
  readonly favorites?: account_api_AccountFavoritesInput | null;
  readonly bank_details?: account_api_AccountBankDetailsInput | null;
  readonly enable_landlord_tool?: boolean | null;
  readonly log?: ReadonlyArray<account_api_LogInput> | null;
  readonly entrypoint?: account_api_EntryPointEnum | null;
  readonly is_verified?: boolean | null;
  readonly contact?: account_api_AccountContactInput | null;
  readonly id?: string | null;
  readonly last_login?: string | null;
  readonly tos_version?: number | null;
}

export interface account_api_AccountLocationInput {
  readonly country?: string | null;
  readonly address4?: string | null;
  readonly building?: string | null;
  readonly city?: string | null;
  readonly gps?: account_api_GpsCoordinatesInput | null;
  readonly postcode?: string | null;
  readonly flat?: string | null;
  readonly address1?: string | null;
  readonly address2?: string | null;
  readonly address3?: string | null;
  readonly street?: string | null;
}

export interface account_api_AccountResourcesInput {
  readonly owned_properties?: number | null;
  readonly viewing_requests?: number | null;
}

export interface account_api_GpsCoordinatesInput {
  readonly longitude?: number | null;
  readonly latitude?: number | null;
}

export interface account_api_LogInput {
  readonly user?: string | null;
  readonly action?: string | null;
  readonly dt?: string | null;
}

export interface account_api_PhoneInput {
  readonly number?: string | null;
  readonly type?: account_api_AccountPhoneTypeEnum | null;
}

export interface cardpayment_api_PaymentLinkItemInput {
  readonly productID?: string | null;
  readonly quantity?: number | null;
  readonly amount?: number | null;
}

export interface common_api_generic_query_QueryParamsFilterInput {
  readonly not?: boolean | null;
  readonly fields?: ReadonlyArray<string> | null;
  readonly value?: ReadonlyArray<string> | null;
  readonly operator?: common_api_generic_query_QueryFilterOperatorEnum | null;
  readonly transformation?: common_api_generic_query_QueryParamsTransformationInput | null;
}

export interface common_api_generic_query_QueryParamsInput {
  readonly filter?: ReadonlyArray<common_api_generic_query_QueryParamsFilterInput> | null;
  readonly partition?: common_api_generic_query_QueryParamsPartitionInput | null;
  readonly pagination?: common_api_generic_query_QueryParamsPaginationInput | null;
  readonly sort?: ReadonlyArray<common_api_generic_query_QueryParamsSortInput> | null;
}

export interface common_api_generic_query_QueryParamsPaginationInput {
  readonly page?: number | null;
  readonly per_page?: number | null;
}

export interface common_api_generic_query_QueryParamsPartitionInput {
  readonly sort?: ReadonlyArray<common_api_generic_query_QueryParamsSortInput> | null;
  readonly limit?: number | null;
  readonly key?: ReadonlyArray<string> | null;
}

export interface common_api_generic_query_QueryParamsSortInput {
  readonly order?: number | null;
  readonly field?: string | null;
}

export interface common_api_generic_query_QueryParamsTransformationInput {
  readonly preprocessor?: common_api_generic_query_QueryFilterTransformationPreprocessorEnum | null;
  readonly param?: ReadonlyArray<string> | null;
}

export interface document_api_DocumentInput {
  readonly file?: document_api_FileInput | null;
  readonly user?: string | null;
  readonly amount?: number | null;
  readonly property?: string | null;
  readonly code?: string | null;
  readonly description?: string | null;
  readonly expiration?: string | null;
  readonly product_id?: string | null;
  readonly type?: document_api_DocumentTypeEnum | null;
  readonly created_at?: string | null;
  readonly updated_at?: string | null;
  readonly removed?: boolean | null;
  readonly state?: document_api_DocumentStateEnum | null;
  readonly issued_at?: string | null;
  readonly type_code?: string | null;
  readonly attributes?: ReadonlyArray<_string__stringInputMap> | null;
  readonly id?: string | null;
}

export interface document_api_FileInput {
  readonly created_at?: string | null;
  readonly size?: number | null;
  readonly sha256?: ReadonlyArray<number> | null;
  readonly description?: string | null;
  readonly deleted_on?: string | null;
  readonly id?: string | null;
  readonly name?: string | null;
  readonly mime?: string | null;
}

export interface extras_api_PropertyInput {
  readonly conditions?: number | null;
  readonly id?: string | null;
  readonly status?: property_api_PropertyStatusEnum | null;
  readonly details?: property_api_PropertyDetailsInput | null;
  readonly rental?: property_api_PropertyRentalInput | null;
  readonly people?: property_api_PropertyPeopleInput | null;
}

export interface gateway_impl_resolvers_admin_OverduePaymentInput {
  readonly tenant_id?: string | null;
  readonly landlord_id?: string | null;
  readonly payment_amount?: number | null;
  readonly overdue_days?: number | null;
  readonly property?: gateway_impl_resolvers_admin_OverduePaymentPropertyInput | null;
}

export interface gateway_impl_resolvers_admin_OverduePaymentPropertyInput {
  readonly id?: string | null;
  readonly property_address?: string | null;
  readonly property_ivr?: string | null;
  readonly secured_rentplan?: boolean | null;
}

export interface gateway_impl_resolvers_admin_allParamsPaginationInput {
  readonly page?: number | null;
  readonly perPage?: number | null;
}

export interface invoice_api_LocationInput {
  readonly address2?: string | null;
  readonly address4?: string | null;
  readonly flat?: string | null;
  readonly building?: string | null;
  readonly country?: string | null;
  readonly address1?: string | null;
  readonly address3?: string | null;
  readonly street?: string | null;
  readonly city?: string | null;
  readonly postcode?: string | null;
}

export interface invoice_api_ProductDetailsContentsInsuranceInput {
  readonly unspecified?: invoice_api_ProductDetailsContentsInsurance_UnspecifiedInput | null;
  readonly endorsements?: ReadonlyArray<invoice_api_ProductDetailsContentsInsurance_ItemInput> | null;
  readonly property?: invoice_api_ProductDetailsContentsInsurance_PropertyInput | null;
  readonly policy_id?: string | null;
  readonly excess?: number | null;
  readonly claims?: ReadonlyArray<invoice_api_ProductDetailsContentsInsurance_ClaimInput> | null;
  readonly flood_claims_ex_clause?: boolean | null;
  readonly accidental?: boolean | null;
  readonly specified?: invoice_api_ProductDetailsContentsInsurance_SpecifiedInput | null;
  readonly pedal_cycles?: invoice_api_ProductDetailsContentsInsurance_PedalCyclesInput | null;
  readonly insureds?: ReadonlyArray<invoice_api_ProductDetailsContentsInsurance_InsuredInput> | null;
}

export interface invoice_api_ProductDetailsContentsInsurance_ClaimInput {
  readonly type?: string | null;
  readonly amount?: number | null;
  readonly date?: string | null;
}

export interface invoice_api_ProductDetailsContentsInsurance_InsuredInput {
  readonly title?: string | null;
  readonly first_name?: string | null;
  readonly last_name?: string | null;
  readonly employment_status?: string | null;
  readonly occupation?: string | null;
}

export interface invoice_api_ProductDetailsContentsInsurance_ItemInput {
  readonly value?: number | null;
  readonly description?: string | null;
  readonly wording?: string | null;
  readonly name?: string | null;
}

export interface invoice_api_ProductDetailsContentsInsurance_PedalCyclesInput {
  readonly enabled?: boolean | null;
  readonly items?: ReadonlyArray<invoice_api_ProductDetailsContentsInsurance_ItemInput> | null;
}

export interface invoice_api_ProductDetailsContentsInsurance_PropertyInput {
  readonly has_partner?: boolean | null;
  readonly type?: string | null;
  readonly subtype?: string | null;
  readonly adults?: number | null;
  readonly has_door_locks?: boolean | null;
  readonly has_window_locks?: boolean | null;
  readonly door_lock_type?: string | null;
}

export interface invoice_api_ProductDetailsContentsInsurance_SpecifiedInput {
  readonly items?: ReadonlyArray<invoice_api_ProductDetailsContentsInsurance_ItemInput> | null;
  readonly enabled?: boolean | null;
}

export interface invoice_api_ProductDetailsContentsInsurance_UnspecifiedInput {
  readonly claim_limit?: number | null;
  readonly enabled?: boolean | null;
}

export interface invoice_api_ProductDetailsContractInput {
  readonly id?: string | null;
  readonly conditions?: invoice_api_RentConditionsInput | null;
  readonly tenant?: string | null;
}

export interface invoice_api_ProductDetailsInput {
  readonly time?: invoice_api_ProductDetailsTimeInput | null;
  readonly property?: invoice_api_ProductDetailsPropertyInput | null;
  readonly contract?: invoice_api_ProductDetailsContractInput | null;
  readonly performance?: invoice_api_ProductDetailsPerformanceInput | null;
  readonly contents_insurance?: invoice_api_ProductDetailsContentsInsuranceInput | null;
  readonly quantity?: number | null;
}

export interface invoice_api_ProductDetailsPerformanceInput {
  readonly performed_date?: string | null;
  readonly desired_performance_date?: string | null;
}

export interface invoice_api_ProductDetailsPropertyInput {
  readonly id?: string | null;
  readonly listing?: string | null;
  readonly location?: invoice_api_LocationInput | null;
  readonly details?: property_api_PropertyDetailsInput | null;
}

export interface invoice_api_ProductDetailsTimeInput {
  readonly end?: string | null;
  readonly start?: string | null;
}

export interface invoice_api_ProductDocumentInput {
  readonly fileid?: string | null;
  readonly code?: string | null;
  readonly document_id?: string | null;
}

export interface invoice_api_ProductInput {
  readonly service_id?: string | null;
  readonly details?: invoice_api_ProductDetailsInput | null;
  readonly documents?: ReadonlyArray<invoice_api_ProductDocumentInput> | null;
  readonly order_id?: string | null;
  readonly remote_sales_price?: number | null;
  readonly remote_sales_tax?: number | null;
  readonly attributes?: ReadonlyArray<_string__stringInputMap> | null;
  readonly id?: string | null;
}

export interface invoice_api_RemoteSalePropertyInput {
  readonly products?: ReadonlyArray<invoice_api_ProductInput> | null;
  readonly id?: string | null;
}

export interface invoice_api_RentConditionsInput {
  readonly rent_plan?: invoice_api_RentPlanEnum | null;
  readonly rent?: number | null;
}

export interface invoice_api_ReportHEIAndDRPBordeauxInput {
  readonly from_time?: string | null;
  readonly to_time?: string | null;
}

export interface invoice_api_ReportHEIBordeauxInput {
  readonly from_time?: string | null;
  readonly to_time?: string | null;
}

export interface invoice_api_ReportRGIBordeauxInput {
  readonly from_time?: string | null;
  readonly to_time?: string | null;
}

export interface invoice_api_ReportTransactionsInput {
  readonly format?: invoice_api_ReportFormatEnum | null;
  readonly invoice_id?: string | null;
}

export interface invoice_api_ServiceItemStatusInput {
  readonly item_id?: string | null;
  readonly status?: extras_api_ServiceStatusEnum | null;
}

export interface invoice_api_UserContactsInput {
  readonly email_primary?: string | null;
  readonly phone_primary?: string | null;
}

export interface invoice_api_UserDataInput {
  readonly location?: invoice_api_LocationInput | null;
  readonly correspondence?: invoice_api_LocationInput | null;
  readonly details?: invoice_api_UserDetailsInput | null;
  readonly contacts?: invoice_api_UserContactsInput | null;
}

export interface invoice_api_UserDetailsInput {
  readonly first_name?: string | null;
  readonly last_name?: string | null;
}

export interface invoice_api_isDownloadReportRequest_ReportOneofInput {
  readonly insurance_products?: invoice_api_ReportFormatEnum | null;
  readonly transactions?: invoice_api_ReportTransactionsInput | null;
  readonly all_products?: invoice_api_ReportFormatEnum | null;
  readonly rgi_bordeaux?: invoice_api_ReportRGIBordeauxInput | null;
  readonly hei_bordeaux?: invoice_api_ReportHEIBordeauxInput | null;
  readonly hei_and_drp_bordeaux?: invoice_api_ReportHEIAndDRPBordeauxInput | null;
  readonly addon_products?: invoice_api_ReportFormatEnum | null;
}

export interface invoice_api_isServicePaymentTransactionAddRequest_FindOneofInput {
  readonly invoice_id?: string | null;
  readonly payment_id?: string | null;
}

export interface listing_api_GpsCoordinatesInput {
  readonly longitude?: number | null;
  readonly latitude?: number | null;
}

export interface listing_api_LocationInput {
  readonly traditional_county?: string | null;
  readonly address3?: string | null;
  readonly flat?: string | null;
  readonly county?: string | null;
  readonly postal_county?: string | null;
  readonly city?: string | null;
  readonly street?: string | null;
  readonly administrative_county?: string | null;
  readonly geo_town?: string | null;
  readonly address4?: string | null;
  readonly address?: string | null;
  readonly district?: string | null;
  readonly postcode?: string | null;
  readonly address1?: string | null;
  readonly building?: string | null;
  readonly geo_county?: string | null;
  readonly address2?: string | null;
  readonly gps?: listing_api_GpsCoordinatesInput | null;
  readonly post_town?: string | null;
  readonly geo_region?: string | null;
  readonly country?: string | null;
}

export interface listing_api_VersionPublicationChangeItemInput {
  readonly invoice_id?: string | null;
  readonly external_id?: string | null;
  readonly external_url?: string | null;
  readonly expire_at?: string | null;
}

export interface offer_api_AdvanceInput {
  readonly available?: boolean | null;
  readonly user?: string | null;
  readonly created_at?: string | null;
  readonly updated_at?: string | null;
  readonly request?: string | null;
  readonly state?: offer_api_AdvanceStateEnum | null;
  readonly total?: number | null;
  readonly fee_percentage?: number | null;
  readonly fee_value?: number | null;
}

export interface offer_api_ClauseInput {
  readonly created_at?: string | null;
  readonly id?: string | null;
  readonly text?: string | null;
  readonly state?: offer_api_ClauseStateEnum | null;
}

export interface offer_api_GuarantorInput {
  readonly phone_primary?: string | null;
  readonly risk?: offer_api_TenantRiskEnum | null;
  readonly check?: string | null;
  readonly rentprofile_created_at?: string | null;
  readonly risk_profile?: string | null;
  readonly billing_address?: string | null;
  readonly bill_documents?: ReadonlyArray<string> | null;
  readonly rentprofile_status?: string | null;
  readonly signature?: ReadonlyArray<offer_api_SignStatusInput> | null;
  readonly first_name?: string | null;
  readonly last_name?: string | null;
  readonly risk_document?: string | null;
  readonly user?: string | null;
  readonly identity_documents?: ReadonlyArray<string> | null;
  readonly email_primary?: string | null;
}

export interface offer_api_LandlordInput {
  readonly first_name?: string | null;
  readonly last_name?: string | null;
  readonly signature?: ReadonlyArray<offer_api_SignStatusInput> | null;
  readonly email_primary?: string | null;
  readonly phone_primary?: string | null;
  readonly billing_address?: string | null;
  readonly user?: string | null;
}

export interface offer_api_LogInput {
  readonly user?: string | null;
  readonly action?: string | null;
  readonly dt?: string | null;
}

export interface offer_api_OfferApproveInput {
  readonly admin?: string | null;
  readonly approved?: boolean | null;
  readonly approved_at?: string | null;
}

export interface offer_api_OfferChangelogInput {
  readonly initiator?: string | null;
  readonly action?: offer_api_OfferActionEnum | null;
  readonly created_at?: string | null;
  readonly old?: string | null;
  readonly new?: string | null;
}

export interface offer_api_OfferConditionsInput {
  readonly pets_description?: string | null;
  readonly comments?: string | null;
  readonly break_clause?: number | null;
  readonly first_rent_payment_date?: string | null;
  readonly rent_payment_day?: number | null;
  readonly deposit_type?: offer_api_DepositTypeEnum | null;
  readonly term_months?: number | null;
  readonly price?: number | null;
  readonly pets?: boolean | null;
  readonly movein_date?: string | null;
  readonly tenants_pitch?: string | null;
  readonly payment_frequency?: number | null;
}

export interface offer_api_OfferDetailsInput {
  readonly adult_flatmates?: boolean | null;
  readonly movein_alone?: boolean | null;
}

export interface offer_api_OfferInput {
  readonly property_ivr?: string | null;
  readonly property_listing?: string | null;
  readonly accepted_at_value?: string | null;
  readonly notifications?: ReadonlyArray<_string_google_golang_org_protobuf_types_known_timestamppb_TimestampInputMap> | null;
  readonly draft_contract?: string | null;
  readonly workflow_instance_id?: string | null;
  readonly landlord?: offer_api_LandlordInput | null;
  readonly changelog?: ReadonlyArray<offer_api_OfferChangelogInput> | null;
  readonly updated_at_value?: string | null;
  readonly administrator?: string | null;
  readonly rent_insurances_generated?: boolean | null;
  readonly original_offer?: string | null;
  readonly details?: offer_api_OfferDetailsInput | null;
  readonly manager?: string | null;
  readonly created_at_value?: string | null;
  readonly state?: offer_api_OfferStateEnum | null;
  readonly schedule_notifications?: ReadonlyArray<_int32_offer_api_ScheduleNotificationInputMap> | null;
  readonly rent_plan?: offer_api_RentPlanEnum | null;
  readonly is_rto?: boolean | null;
  readonly guarantors?: ReadonlyArray<offer_api_GuarantorInput> | null;
  readonly log?: ReadonlyArray<offer_api_LogInput> | null;
  readonly clause?: ReadonlyArray<offer_api_ClauseInput> | null;
  readonly is_locked?: boolean | null;
  readonly tenants?: ReadonlyArray<offer_api_TenantInput> | null;
  readonly schedule_generated?: boolean | null;
  readonly conditions?: offer_api_OfferConditionsInput | null;
  readonly test?: boolean | null;
  readonly contract_created_at?: string | null;
  readonly property?: string | null;
  readonly rent_plan_selected_at?: string | null;
  readonly cancelled_at_value?: string | null;
  readonly id?: string | null;
  readonly ready_for_signing?: offer_api_OfferReadySigningInput | null;
  readonly denied_at_value?: string | null;
  readonly guarantor_required?: boolean | null;
  readonly deleted?: boolean | null;
  readonly approve?: offer_api_OfferApproveInput | null;
  readonly landlords?: ReadonlyArray<offer_api_LandlordInput> | null;
  readonly contract?: string | null;
  readonly viewing?: string | null;
  readonly paid_statuses?: offer_api_PaidStatusesInput | null;
  readonly draft_contract_created_at?: string | null;
}

export interface offer_api_OfferReadySigningInput {
  readonly landlord?: boolean | null;
  readonly tenant?: boolean | null;
}

export interface offer_api_PaidStatusesInput {
  readonly tenants_services?: boolean | null;
  readonly deposit_replacement?: boolean | null;
  readonly deposit_replacement_setup?: boolean | null;
  readonly standing_order?: boolean | null;
  readonly standing_order_setup?: boolean | null;
}

export interface offer_api_ScheduleNotificationInput {
  readonly is_notified?: boolean | null;
  readonly started_at?: string | null;
}

export interface offer_api_SignStatusInput {
  readonly state?: offer_api_SignStatusStateEnum | null;
  readonly time?: string | null;
}

export interface offer_api_TenantInput {
  readonly last_name?: string | null;
  readonly email_primary?: string | null;
  readonly guarantor_required?: boolean | null;
  readonly bill_documents?: ReadonlyArray<string> | null;
  readonly annual_income?: number | null;
  readonly kids_no?: number | null;
  readonly signature?: ReadonlyArray<offer_api_SignStatusInput> | null;
  readonly user?: string | null;
  readonly check?: string | null;
  readonly age?: number | null;
  readonly identity_documents?: ReadonlyArray<string> | null;
  readonly rentprofile_status?: string | null;
  readonly rentprofile_created_at?: string | null;
  readonly employment_status?: offer_api_EmploymentStatusEnum | null;
  readonly risk_document?: string | null;
  readonly first_name?: string | null;
  readonly paying?: number | null;
  readonly risk_profile?: string | null;
  readonly risk?: offer_api_TenantRiskEnum | null;
  readonly billing_address?: string | null;
  readonly phone_primary?: string | null;
}

export interface property_api_AdminVerifyInput {
  readonly publication?: property_api_AdminVerifyItemInput | null;
  readonly landlord_ownership?: property_api_AdminVerifyItemInput | null;
  readonly submitter_management?: property_api_AdminVerifyItemInput | null;
  readonly address?: property_api_AdminVerifyItemInput | null;
  readonly description?: property_api_AdminVerifyItemInput | null;
  readonly photos?: property_api_AdminVerifyItemInput | null;
  readonly documents?: property_api_AdminVerifyItemInput | null;
  readonly fee?: property_api_AdminVerifyItemInput | null;
}

export interface property_api_AdminVerifyItemInput {
  readonly admin?: string | null;
  readonly verified?: boolean | null;
  readonly verified_at?: string | null;
}

export interface property_api_GpsCoordinatesInput {
  readonly longitude?: number | null;
  readonly latitude?: number | null;
}

export interface property_api_GuarantorInput {
  readonly first_name?: string | null;
  readonly last_name?: string | null;
  readonly email_primary?: string | null;
  readonly phone_primary?: string | null;
}

export interface property_api_InvitationStatusInput {
  readonly invitee_first_name?: string | null;
  readonly sent?: string | null;
  readonly workflow?: string | null;
  readonly inviter_id?: string | null;
  readonly invitee_phone?: string | null;
  readonly invitee_email?: string | null;
  readonly invitee_last_name?: string | null;
  readonly invitation_id?: string | null;
  readonly invitee_id?: string | null;
  readonly pending?: boolean | null;
}

export interface property_api_PropertyBankDetailsInput {
  readonly account_number?: string | null;
  readonly account_name?: string | null;
  readonly personal_reference?: string | null;
  readonly society?: string | null;
  readonly sort_code?: string | null;
}

export interface property_api_PropertyDescriptionInput {
  readonly post_comment?: string | null;
  readonly videos?: ReadonlyArray<string> | null;
  readonly post_property_pitch?: string | null;
  readonly photos?: ReadonlyArray<string> | null;
  readonly plans?: ReadonlyArray<string> | null;
  readonly video_embeds?: ReadonlyArray<string> | null;
  readonly tags?: ReadonlyArray<string> | null;
  readonly post_area_pitch?: string | null;
  readonly post_property_admin_pitch?: string | null;
  readonly post_headline?: string | null;
  readonly post_outer_description?: string | null;
}

export interface property_api_PropertyDetailsInput {
  readonly amenity_furniture?: property_api_PropertyFurnitureEnum | null;
  readonly bathrooms_no?: number | null;
  readonly livingrooms_no?: number | null;
  readonly amenity_outside_space?: ReadonlyArray<property_api_PropertyOutsideSpaceEnum> | null;
  readonly amenity_parking?: ReadonlyArray<property_api_PropertyParkingEnum> | null;
  readonly pets_allowed?: boolean | null;
  readonly type_other?: string | null;
  readonly area?: number | null;
  readonly bedrooms_no?: number | null;
  readonly kitchen_no?: number | null;
  readonly type?: property_api_PropertyTypeEnum | null;
}

export interface property_api_PropertyLocationInput {
  readonly postal_county?: string | null;
  readonly postcode?: string | null;
  readonly post_town?: string | null;
  readonly geo_county?: string | null;
  readonly gps?: property_api_GpsCoordinatesInput | null;
  readonly traditional_county?: string | null;
  readonly address?: string | null;
  readonly street?: string | null;
  readonly address2?: string | null;
  readonly address3?: string | null;
  readonly administrative_county?: string | null;
  readonly address1?: string | null;
  readonly address4?: string | null;
  readonly flat?: string | null;
  readonly building?: string | null;
  readonly country?: string | null;
  readonly geo_region?: string | null;
  readonly district?: string | null;
  readonly city?: string | null;
  readonly county?: string | null;
  readonly geo_town?: string | null;
}

export interface property_api_PropertyPeopleInput {
  readonly manager_invitation?: property_api_InvitationStatusInput | null;
  readonly landlord_invitation?: property_api_InvitationStatusInput | null;
  readonly submitter?: string | null;
  readonly tenants?: ReadonlyArray<property_api_TenantInput> | null;
  readonly landlord?: string | null;
  readonly guarantors?: ReadonlyArray<property_api_GuarantorInput> | null;
  readonly administrator?: string | null;
  readonly manager_to_landlord_invitation?: property_api_InvitationStatusInput | null;
  readonly manager?: string | null;
}

export interface property_api_PropertyRentalInput {
  readonly duration_max_months?: number | null;
  readonly inventory_report?: boolean | null;
  readonly rental_price?: number | null;
  readonly bills_included?: boolean | null;
  readonly dss_available?: boolean | null;
  readonly earliest_movein?: any | null;
  readonly tenancy_deposit_insurance?: boolean | null;
  readonly duration_min_months?: number | null;
  readonly bills_covered?: ReadonlyArray<property_api_PropertyBillTypeEnum> | null;
  readonly deposit_replacement_fee?: number | null;
}

export interface property_api_TenantContactInput {
  readonly first_name?: string | null;
  readonly last_name?: string | null;
  readonly email_primary?: string | null;
  readonly phone_primary?: string | null;
}

export interface property_api_TenantInput {
  readonly risk?: property_api_TenantRiskEnum | null;
  readonly tenant_contact?: property_api_TenantContactInput | null;
  readonly user?: string | null;
  readonly contract?: string | null;
  readonly active?: boolean | null;
  readonly movedin?: string | null;
  readonly movedout?: string | null;
}

export interface viewing_api_ViewingSpecInput {
  readonly user_id?: string | null;
  readonly select?: viewing_api_ViewingSpecSelectInput | null;
}

export interface viewing_api_ViewingSpecSelectInput {
  readonly states?: ReadonlyArray<viewing_api_ViewingStateEnum> | null;
  readonly property_id?: string | null;
  readonly listing_id?: string | null;
  readonly offer_id?: string | null;
  readonly viewing_id?: string | null;
  readonly token?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
