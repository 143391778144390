import React, { lazy } from "react";

import styled from "@emotion/styled";
import { Route, Switch } from "react-router-dom";

import Dashboard from "./components/Dashboard";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";

// TODO: Use less instead of css, but we can't use it now because node modules are very old
import "antd/dist/antd.variable.css";
import "antd/dist/antd.css";
import "antd-button-color/dist/css/style.css";

const MainContainer = (props) => {
  return (
    <Main>
      <Header />
      <Sidebar />
      <Content>{props.children}</Content>
    </Main>
  );
};

const AdminHome = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "admin_home" */ "./entities/admin"
  )
);
const EventsPropertyHome = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "events_property_home" */ "./entities/events-property"
  )
);
const EventsUserHome = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "events_user_home" */ "./entities/events-user"
  )
);
const MaintenanceHome = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "maintenance_home" */ "./entities/maintenance"
  )
);
const OffersHome = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "offers_home" */ "./entities/offers"
  )
);
const PaymentsHome = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "payments_home" */ "./entities/payments/List"
  )
);
const PlatformUsersHome = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "platform_users_home" */ "./entities/platform-users"
  )
);
const PropertyHome = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "property_home" */ "./entities/listing"
  )
);

const AccountStatement = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "account_statement" */ "./entities/payments/AccountStatement/index"
  )
);
const InvitationsHome = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "invitations_home" */ "./entities/invitations"
  )
);
const InvoiceDetails = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "invoice_details" */ "./entities/payments/Invoices/View"
  )
);
const Invoices = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "invoices" */ "./entities/payments/Invoices"
  )
);
const Mortgages = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "mortgages" */ "./entities/mortgages"
  )
);
const ProductsPage = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "products_page" */ "./entities/products"
  )
);
const SystemNotifications = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "transaction_details" */ "./entities/notifications"
  )
);
const TransactionDetails = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "transaction_details" */ "./entities/payments/AccountStatement/Transaction"
  )
);

const Tenancies = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "tenancies" */ "./entities/tenancies"
  )
);

const AdvancedRentPage = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "advanced_rent" */ "./entities/advanced-rent-requests"
  )
);

const PropertyPayments = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "property_payments" */ "./entities/payments/PropertyPayments"
  )
);

const Comments = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "comments" */ "./entities/comments"
  )
);

const UnmatchedTransactions = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "unmatched_transactions" */ "./entities/unmatched-transactions"
  )
);

const OverduePayments = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "overdue_payments" */ "./entities/overdue-payments"
  )
);

const PayoutsMade = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "payouts_made" */ "./entities/payouts-made"
  )
);

const RemoteSales = lazy(() =>
  import(
    /*webpackPrefetch: true, webpackChunkName: "remote_sales" */ "./entities/remote-sales"
  )
);

export type AppProps = {};
const App: React.FC<AppProps> = () => {
  return (
    <Switch>
      <Route component={RemoteSales} path="/remote-sales" />
      <Route>
        <MainContainer>
          <Switch>
            <Route component={Dashboard} exact path="/" />
            <Route component={AdminHome} path="/admin" />
            <Route component={PropertyHome} path="/listing" />
            <Route component={OffersHome} path="/offers" />
            <Route component={PlatformUsersHome} path="/users" />
            <Route component={PaymentsHome} path="/payments" />
            <Route component={InvoiceDetails} path="/invoices/details" />
            <Route component={Invoices} path="/invoices" />
            <Route component={Tenancies} path="/tenancies" />
            <Route component={OverduePayments} path="/overdue-payments" />
            <Route
              component={UnmatchedTransactions}
              path="/unmatched-transactions"
            />
            <Route component={PayoutsMade} path="/payouts-made" />
            <Route
              component={TransactionDetails}
              path={`/statement/:transactionId`}
            />
            <Route component={AccountStatement} path="/statement" />
            <Route component={AdvancedRentPage} path="/advanced" />
            <Route
              component={PropertyPayments}
              path="/property-payments/:propertyId"
            />
            <Route component={MaintenanceHome} path="/maintenances" />
            <Route component={EventsPropertyHome} path="/events-property" />
            <Route component={EventsUserHome} path="/events-user" />
            <Route component={InvitationsHome} path="/invitations" />
            <Route component={ProductsPage} path="/products" />
            <Route component={Mortgages} path="/mortgages-lead-list" />
            <Route component={SystemNotifications} path="/notifications" />
            <Route component={Comments} path="/comments" />
          </Switch>
        </MainContainer>
      </Route>
    </Switch>
  );
};

const Main = styled.div`
  position: realtive;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100vh;
`;
const Content = styled.div`
  position: absolute;
  top: 60px;
  left: 280px;
  right: 0;
  bottom: 0;
  overflow: auto;
  overflow-y: scroll;
  padding: 25px 40px 0;
`;

export default App;
