import React, { FC, useEffect } from "react";

import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { UnreachableCaseError } from "ts-essentials";

import { selectNotifications } from "../../notifications.selectors";
import { actions, Severity } from "../../notifications.slice";
import theme from "../theme";

const formatTitle = (severity: Severity) => {
  switch (severity) {
    case Severity.Default:
    case Severity.Info:
      return "Info";
    case Severity.Error:
      return "Error";
    case Severity.Success:
      return "Success";
    case Severity.Warning:
      return "Warning";
    default:
      throw new UnreachableCaseError(severity);
  }
};

const SystemNotifications = () => {
  const notifications = useSelector(selectNotifications);
  return createPortal(
    <NotificationsContainer>
      {notifications.map((n) => (
        <Notification key={n.uuid} notification={n} />
      ))}
    </NotificationsContainer>,
    document.body
  );
};

const NotificationsContainer = styled.div`
  position: fixed;
  top: 70px;
  right: 0;
  padding: 10px 30px;
  z-index: 9;
  width: 360px;
  overflow: hidden;
`;
const NotificationContainer = styled.div<{ severity: Severity }>`
  position: relative;
  padding: 0 0 12px;
  width: 300px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 12px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }

  &:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 13px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.98);
  }

  ${({ severity }) => {
    switch (severity) {
      case Severity.Error:
        return css`
          &:before {
            background: ${theme.colors.main.red};
          }

          h4 {
            color: ${theme.colors.main.red};
          }
        `;
      case Severity.Warning:
        return css`
          &:before {
            background: ${theme.colors.main.orange};
          }

          h4 {
            color: ${theme.colors.main.orange};
          }
        `;
      case Severity.Info:
        return css`
          &:before {
            background: ${theme.colors.icons.darkBlue};
          }

          h4 {
            color: ${theme.colors.icons.darkBlue};
          }
        `;
      case Severity.Success:
        return css`
          &:before {
            background: ${theme.colors.main.green};
          }

          h4 {
            color: ${theme.colors.main.green};
          }
        `;
      case Severity.Default:
        return css`
          &:before {
            background: ${theme.colors.main.midGrey};
          }

          h4 {
            color: ${theme.colors.main.mainGrey};
          }
        `;
      default:
        throw new UnreachableCaseError(severity);
    }
  }}
`;
const Content = styled.div`
  padding: 12px 20px;
  position: relative;
  z-index: 2;
`;
const Title = styled.h4`
  font-size: 14px;
  margin: 0 0 5px;
`;
const Message = styled.div`
  font-size: 14px;
  line-height: 1.5;
`;

const Close = styled.button`
  border: 0;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 2px;
    left: 0;
    right: 0;
    top: 19px;
    height: 2px;
    background: ${theme.colors.main.midGrey};
    margin: 0 auto;
    transition: all 0.25s;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    &::before,
    &::after {
      background: ${theme.colors.main.red};
    }
  }
`;

export default SystemNotifications;

const Notification: FC<{
  notification: {
    message: React.ReactNode;
    severity: Severity;
    uuid: string;
  };
}> = ({ notification }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(actions.notificationTimedOut(notification.uuid));
    }, 5000);
    return () => {
      if (timer != null) {
        clearTimeout(timer);
      }
    };
  }, [dispatch, notification.uuid]);
  return (
    <NotificationContainer
      key={notification.uuid}
      severity={notification.severity}
    >
      <Content>
        <Title>{formatTitle(notification.severity)}</Title>
        <Message>{notification.message}</Message>
        <Close
          onClick={() => {
            dispatch(actions.notificationTimedOut(notification.uuid));
          }}
        />
      </Content>
    </NotificationContainer>
  );
};
