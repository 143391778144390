import React, { Suspense } from "react";

import { Global } from "@emotion/core";
import { ApolloProvider } from "react-apollo";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import ReactDOM from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { HashRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import Authentication from "./entities/auth/Authentication";
import history from "./history";
import * as serviceWorker from "./serviceWorker";
import { persistor, store } from "./store";
import Spinner from "./ui/atoms/Spinner";
import globalStyles from "./ui/globalStyles";
import ErrorFallback from "./ui/molecules/ErrorFallback";
import SystemNotifications from "./ui/molecules/Notification";
import initApolloClient from "./util/init-apollo-client";

const apolloClient = initApolloClient();

ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <Global styles={globalStyles} />
          <DndProvider backend={HTML5Backend}>
            <ApolloProvider client={apolloClient}>
              <Suspense fallback={<Spinner />}>
                <Authentication>
                  <App />
                  <SystemNotifications />
                </Authentication>
              </Suspense>
            </ApolloProvider>
          </DndProvider>
        </Router>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
