import React, { FC } from "react";

import { LogoutOutlined } from "@ant-design/icons";
import AntdButton from "antd-button-color";
import { ApolloClient } from "apollo-client";
import Cookies from "js-cookie";
import { withApollo, WithApolloClient } from "react-apollo";

import { AuthContext } from "./AuthContext";
import AdminLogout from "./graphql/mutation/logout.graphql";

export const handleLogout = (
  client: ApolloClient<any>,
  logOut: Function | void
): void => {
  client.mutate({ mutation: AdminLogout }).then(() => {
    Cookies.remove("accessToken");
    window.localStorage.removeItem("adminId");
    logOut && logOut();
  });
};

export type LogoutProps = WithApolloClient<{
  logOut?: Function | void;
}>;

const Logout: FC<LogoutProps> = ({ client, logOut }) => {
  return (
    <AuthContext.Consumer>
      {({ logOut }) => (
        <AntdButton
          icon={<LogoutOutlined />}
          onClick={() => handleLogout(client, logOut)}
        >
          Logout
        </AntdButton>
      )}
    </AuthContext.Consumer>
  );
};

export default withApollo(Logout);
