/* eslint-disable react/no-unused-state*/
import React from "react";

import { NormalizedCacheObject } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloConsumer } from "react-apollo";

import Spinner from "../../ui/atoms/Spinner";
import {
  AuthFetchAdmin,
  AuthFetchAdminVariables,
} from "./graphql/queries/__generated__/AuthFetchAdmin";
import AUTH_FETCH_ADMIN from "./graphql/queries/admin.graphql";

interface ContextProps {
  email?: string | null | undefined;
  loggedIn: boolean;
  logIn: () => void;
  logOut: () => void;
}

export const AuthContext = React.createContext<Partial<ContextProps>>({
  email: "",
  loggedIn: false,
  logIn: () => void 0,
  logOut: () => void 0,
});

interface Props {
  client: ApolloClient<NormalizedCacheObject>;
}

interface State {
  email?: string | null | undefined;
  loggedIn: boolean;
  loading: boolean;
  logIn: () => void;
  logOut: () => void;
}

class AuthProviderImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: "",
      loggedIn: false,
      loading: true,
      logIn: () => {
        this.setState(() => ({
          loggedIn: true,
        }));
      },
      logOut: () => {
        this.setState(() => ({
          loggedIn: false,
        }));
      },
    };
  }

  componentDidMount() {
    this.fetchAuth();
    window.addEventListener("focus", this.fetchAuth);
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.fetchAuth);
  }

  fetchAuth = async () => {
    try {
      this.setState({ loading: true });
      const response = await this.props.client.query<
        AuthFetchAdmin,
        AuthFetchAdminVariables
      >({
        query: AUTH_FETCH_ADMIN,
        fetchPolicy: "network-only",
        variables: {
          id: window.localStorage.getItem("adminId"),
        },
      });

      if (response.errors) {
        this.state.logOut();
      } else {
        const adminId = response.data?.admin?.Admin?.id;
        if (adminId && !this.state.loggedIn) {
          this.state.logIn();
          this.setState({
            email: response?.data?.admin?.Admin?.contact?.email_primary,
          });
        }
      }
    } catch (error) {
      this.state.logOut();
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { children } = this.props;

    return (
      <AuthContext.Provider value={this.state}>
        {this.state.loading && !this.state.loggedIn ? <Spinner /> : children}
      </AuthContext.Provider>
    );
  }
}

export const AuthProvider = (props) => (
  <ApolloConsumer>
    {(client) => <AuthProviderImpl client={client} {...props} />}
  </ApolloConsumer>
);

export default AuthProvider;
