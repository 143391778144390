import React from "react";

import styled from "@emotion/styled";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";

import {
  PropertiesList,
  PropertiesListVariables,
} from "../../entities/listing/graphql/queries/__generated__/PropertiesList";
import ALL_PROPERTIES from "../../entities/listing/graphql/queries/GetListOfProperties.graphql";
import {
  OffersList,
  OffersListVariables,
} from "../../entities/offers/graphql/queries/__generated__/OffersList";
import ALL_OFFERS from "../../entities/offers/graphql/queries/GetListOfOffers.graphql";
import {
  PaymentsGetPaymentsCount,
  PaymentsGetPaymentsCountVariables,
} from "../../entities/payments/graphql/queries/__generated__/PaymentsGetPaymentsCount";
import ALL_PAYMENTS from "../../entities/payments/graphql/queries/PaymentsGetPaymentsCount.graphql";
import { common_api_generic_query_QueryFilterOperatorEnum } from "../../globalTypes";
import Spinner from "../../ui/atoms/Spinner";
import SystemError from "../../ui/atoms/SystemError";

export type DashboardProps = {};

const Dashboard: React.FC<DashboardProps> = () => {
  return (
    <Container>
      <Section>
        <Query<PropertiesList, PropertiesListVariables>
          query={ALL_PROPERTIES}
          variables={{
            pagination: { page: 1, perPage: 1 },
            filter: [
              {
                operator:
                  common_api_generic_query_QueryFilterOperatorEnum.EQUALS,
                fields: ["status"],
                value: ["VERIFYING"],
              },
            ],
          }}
        >
          {({ data, loading, error }) => {
            if (error) {
              return <SystemError error={error} />;
            } else if (loading) {
              return <Spinner />;
            }

            const count = data?.admin?.allProperties?.total ?? 0;

            return count > 0 ? (
              <Text>
                <strong>
                  {count} {count > 1 ? "Listings need" : "Listing needs"}{" "}
                  verifying
                </strong>
                <br />
                <Link className="link" to="/listing">
                  view listings &rarr;
                </Link>
              </Text>
            ) : (
              <Text>No listings to verify</Text>
            );
          }}
        </Query>
      </Section>

      <Section>
        <Query<OffersList, OffersListVariables>
          query={ALL_OFFERS}
          variables={{
            pagination: { page: 1, perPage: 1 },
            filter: [
              {
                operator:
                  common_api_generic_query_QueryFilterOperatorEnum.EQUALS,
                fields: ["state"],
                value: ["SIGNING"],
              },
            ],
          }}
        >
          {({ data, loading, error }) => {
            if (error) {
              return <SystemError error={error} />;
            } else if (loading) {
              return <Spinner />;
            }

            const count = data?.admin?.allOffers?.total ?? 0;

            return count > 0 ? (
              <Text>
                <strong>
                  {count} {count > 1 ? "Contracts need" : "Contract needs"} to
                  be prepared
                </strong>
                <br />
                <Link className="link" to="/offers">
                  view offers &rarr;
                </Link>
              </Text>
            ) : (
              <Text>No contracts to prepare</Text>
            );
          }}
        </Query>
      </Section>

      <Section>
        <Query<PaymentsGetPaymentsCount, PaymentsGetPaymentsCountVariables>
          query={ALL_PAYMENTS}
          variables={{
            filter: [
              {
                operator:
                  common_api_generic_query_QueryFilterOperatorEnum.EQUALS,
                fields: ["status"],
                value: ["PAYMENT_CONFIRM"],
              },
            ],
          }}
        >
          {({ data, loading, error }) => {
            if (error) {
              return <SystemError error={error} />;
            } else if (loading) {
              return <Spinner />;
            }

            const count = data?.admin?.allPayments?.total ?? 0;

            return count > 0 ? (
              <Text>
                <strong>
                  {count} {count > 1 ? "Payments need" : "Payment needs"}{" "}
                  verifying
                </strong>
                <br />
                <Link className="link" to="/payments">
                  view payments &rarr;
                </Link>
              </Text>
            ) : (
              <Text>No payments to verify</Text>
            );
          }}
        </Query>
      </Section>
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Section = styled.section`
  height: 100px;
  overflow: hidden;
  margin-bottom: 10px;
`;

const Text = styled.div`
  font-size: 18px;
`;
