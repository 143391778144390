export const regExpUkPhoneNumber = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/;
export const regExpEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const regExpUkPostCode = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
export const regExpPhoneNumber = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;

export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), void 0);

export const required = (errorMessage: string = "Required") => (value) =>
  value ? void 0 : errorMessage;

export const requiredWithZero = (errorMessage: string = "Required") => (
  value
) => (value === 0 || value ? void 0 : errorMessage);

export const validUkNumber = (
  errorMessage: string = "It is not UK phone number"
) => (value) => (regExpUkPhoneNumber.test(value) ? void 0 : errorMessage);

export const validEmail = (errorMessage: string = "Incorrect email") => (
  value
) => (regExpEmail.test(value) ? void 0 : errorMessage);

export const validUkPostcode = (
  errorMessage: string = "It is not UK postcode"
) => (value) => (regExpUkPostCode.test(value) ? void 0 : errorMessage);

export const validPhoneNumber = (
  errorMessage: string = "It is not phone number"
) => (value) => (regExpPhoneNumber.test(value) ? void 0 : errorMessage);

export const validRanges = (relatedField: string) => (value, allValues) => {
  return (!value && !allValues[relatedField]) ||
    (value * 1 > 0 &&
      allValues[relatedField] * 1 > 0 &&
      allValues.min * 1 < +allValues.max * 1)
    ? void 0
    : "You must enter both values";
};

type DateInRangeOptions = {
  min?: Date;
  max?: Date;
};

export const dateInRange = (
  { min, max }: DateInRangeOptions,
  errorMessage: string = "Invalid date"
) => (date: Date | string) => {
  try {
    const currentDate = new Date(date).getTime();

    if (min && max) {
      return min.getTime() <= currentDate && currentDate <= max.getTime()
        ? void 0
        : errorMessage;
    }

    if (min) {
      return min.getTime() <= currentDate ? void 0 : errorMessage;
    }

    if (max) {
      return currentDate <= max.getTime() ? void 0 : errorMessage;
    }
  } catch (e) {
    return "Provided string is not a date";
  }

  return errorMessage;
};

export const isEmpty = <T>(
  value: T | null | undefined
): value is null | undefined =>
  value == null || (typeof value === "string" && value.trim().length === 0);

export const maxLength = (max: number, errorMessage?: string) => {
  if (!errorMessage) {
    errorMessage = "Too long";
  }

  return (value) =>
    typeof value === "string" || Array.isArray(value)
      ? value.length > max
        ? errorMessage
        : undefined
      : undefined;
};

export const minLength = (min: number, errorMessage?: string) => {
  if (!errorMessage) {
    errorMessage = "Too short";
  }

  return (value) =>
    typeof value === "string" || Array.isArray(value)
      ? value.length < min
        ? errorMessage
        : undefined
      : undefined;
};

export const composeFieldValidators = (...validators) => {
  // let validators;
  // for (let _len = args.length, validators = new Array(_len), _key = 0; _key < _len; _key++) {
  //   validators[_key] = args[_key];
  // }

  return (value, values) =>
    Array.from(validators)
      .filter((v) => typeof v === "function")
      .reduce(
        (error, validator) => error || validator(value, values),
        undefined
      );
};
