const theme = {
  colors: {
    main: {
      darkGrey: "#2c2c39",
      darkRed: "#cd0000",
      green: "#06c500",
      lightBlue: "#bbeafb",
      lightGrey: "#f5f5f6",
      lightRed: "#ffcece",
      lightYellow: "#fffade",
      lightGreen: "#eeffed",
      mainBlue: "#00b8ff",
      mainGrey: "#3b3b4d",

      midGreen: "#079503",
      midGrey: "#d8d8d8",
      orange: "#ffad00",
      red: "#f00",
      yellow: "#f0c233",
    },
    icons: {
      darkBlue: "#008bc1",
      mainBlue: "#00b8ff",
      lightBlue1: "#abe7ff",
      lightBlue2: "#d5f3ff",
      white: "#fff",
    },
    border: {
      default: "#d8d8db",
      focus: "#00b8ff",
      error: "#f00",
      disabled: "#f5f5f6",
      success: "#06c500",
      waiting: "#ffad00",
    },
  },
  boxShadow: {
    grey: "0 10px 30px 0 rgba(59, 59, 77, 0.25)",
  },
};

export default theme;
