// @flow
import {
  defaultDataIdFromObject,
  InMemoryCache,
  NormalizedCacheObject,
} from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, Observable } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { WebSocketLink } from "apollo-link-ws";
import { GraphQLError } from "graphql";
import gql from "graphql-tag";
import Cookies from "js-cookie";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { oc } from "ts-optchain";

import { NotificationDefinitionOutputFieldsFragment as NotificationDefinitionOutput } from "../entities/notifications/graphql/fragments/NotificationDefinitionOutputFields.generated";
import { ScheduledPaymentOutputFields } from "../entities/payments/graphql/fragments/__generated__/ScheduledPaymentOutputFields";
import {
  RefreshToken,
  RefreshTokenVariables,
} from "./__generated__/RefreshToken";

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

const shouldRetry = (errors: readonly GraphQLError[]) =>
  errors &&
  errors instanceof Array &&
  errors.some((e) => e.message === "auth error") &&
  Cookies.get("refreshToken") != null;

export default () => {
  if (apolloClient != null) {
    return apolloClient;
  }
  const uri = process.env.REACT_APP_API_URL;
  if (!uri) {
    throw new Error(
      "REACT_APP_API_URL is undefined, set it to the url of graphql endpoint"
    );
  }

  const wsClient = new SubscriptionClient(uri, {
    reconnect: true,
    connectionParams: {
      authToken: Cookies.get("accessToken"),
    },
  });
  const wsLink = new WebSocketLink(wsClient);

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }): any => {
      if (
        oc(networkError as any)[0].message() === "auth error" ||
        oc(graphQLErrors)[0].message() === "auth error" ||
        (oc(graphQLErrors)[0].message() === "token_expired" &&
          Cookies.get("refreshToken"))
      ) {
        // Let's refresh token through async request
        return new Observable((observer) => {
          const refresh_token = Cookies.get("refreshToken");

          if (refresh_token != null) {
            apolloClient!
              .mutate<RefreshToken, RefreshTokenVariables>({
                mutation: RefreshTokenDocument,
                variables: { refresh_token },
              })
              .then((result) => {
                if (result.data?.auth?.refresh != null) {
                  const {
                    access_token,
                    refresh_token,
                  } = result.data.auth.refresh;
                  Cookies.set("accessToken", access_token);
                  Cookies.set("refreshToken", refresh_token);
                  // @ts-ignore
                  wsClient.sendMessage("", "connection_init", {
                    authToken: access_token,
                  });
                } else {
                  Cookies.remove("accessToken");
                  Cookies.remove("refreshToken");
                  apolloClient!.resetStore();
                  observer.error(result.errors);
                }
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                // Retry last failed request
                forward(operation).subscribe(subscriber);
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(
                  "No refresh or client token available, we force user to login"
                );
                // eslint-disable-next-line no-console
                console.error(error);
                // No refresh or client token available, we force user to login
                observer.error(error);
              });
          } else {
            observer.error("");
          }
        });
      }
      return undefined;
    }
  );

  const promoteLink = new ApolloLink((operation, forward) => {
    // @ts-ignore
    return forward(operation).map((data) => {
      if (operation.operationName === "UserLogout") {
        // @ts-ignore
        wsClient.sendMessage("", "connection_init", {
          authToken: "",
        });
      }
      if (data && data.errors && data.errors.length > 0) {
        if (shouldRetry(data.errors)) {
          throw data.errors;
        }
      }
      return data;
    });
  });

  const authLink = setContext((_, { headers }) => {
    const accessToken = (Cookies.get("accessToken") || "").replace(
      /[^a-zA-Z0-9=_+.-]/g,
      ""
    );
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `bearer ${accessToken}` : "",
      },
    };
  });

  const cache = new InMemoryCache({
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case "notification_api_NotificationDefinitionOutput":
          return `notification_api_NotificationDefinitionOutput:${
            (responseObject as NotificationDefinitionOutput).ID
          }`;
        case "offer_api_ScheduledPaymentOutput":
          return `offer_api_ScheduledPaymentOutput:${
            (responseObject as ScheduledPaymentOutputFields).id
          }:${(responseObject as ScheduledPaymentOutputFields).offer?.id}`;
        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
  });

  apolloClient = new ApolloClient({
    connectToDevTools: true,
    link: ApolloLink.from([errorLink, promoteLink, authLink, wsLink]),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "all",
      },
      query: {
        fetchPolicy: "cache-first",
        errorPolicy: "all",
      },
      mutate: {
        errorPolicy: "all",
      },
    },
  });

  return apolloClient;
};
const RefreshTokenDocument = gql`
  mutation RefreshToken($refresh_token: String!) {
    auth {
      refresh(refresh_token: $refresh_token) {
        access_token
        refresh_token
        success
        errors
      }
    }
  }
`;
