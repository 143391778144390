// TODO: const MENU
// [ { title: string, list: [ testId, to, title ] } ]

export const USERS_ADMINISTRATION_PATHS = [
  "/admin",
  "/users",
  "/re-engagement",
  "/notifications",
];

export const PROPERTY_ADMINISTRATION_PATHS = [
  "/listing",
  "/offers",
  "/tenancies",
  "/invoices",
  "/payments",
  "/statement",
  "/maintenances",
  "/invitations",
  "/overdue-payments",
  "/payouts-made",
  "/unmatched-transactions",
];

export const PRODUCTS_PATHS = ["/products", "/advanced"];

export const REPORTS_PATHS = ["/events-property", "/events-user"];

export const MORTGAGES_PATHS = ["/mortgages-lead-list"];

export const BLOG_ADMINISTRATION_PATHS = ["/comments"];
