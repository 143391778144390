import React from "react";

import styled from "@emotion/styled";

export type Props = { error: string | Error };
const SystemError: React.FC<Props> = ({ error }) => {
  return (
    <Container>
      <S>
        <span aria-label="error" aria-labelledby="error" role="img">
          ☠️
        </span>
      </S>
      Something went wrong: <br />
      <b>{error.toString()}</b>
    </Container>
  );
};

const Container = styled.div`
  border: solid 1px #ff000066;
  background: #ff000011;
  padding: 16px 20px;
  font-size: 16px;
  color: #d22;
  text-align: center;
  max-width: 420px;
  margin: 20px auto;
`;

const S = styled.div`
  font-size: 24px;
  display: block;
`;

export default SystemError;
