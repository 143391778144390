import React, { ComponentProps, FC } from "react";

import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { FieldMetaState, FieldRenderProps } from "react-final-form";

import theme from "../theme";

export const TextInput: FC<
  { errorOnly?: boolean } & FieldRenderProps<string, HTMLElement> &
    ComponentProps<"input">
> = ({ input, ...props }) => <Input {...input} {...props} />;

const Input = styled.input<{
  meta: FieldMetaState<unknown> | undefined;
  errorOnly?: boolean;
}>`
  width: 100%;
  display: block;
  padding: 9px 12px;
  border: solid 1px ${theme.colors.border.default};
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  transition: all 0.25s;
  background: white;

  &:focus {
    border-color: ${theme.colors.border.focus};
  }
  ${({ meta, errorOnly }) =>
    meta?.touched && meta?.error != null
      ? errorOnly
        ? css`
            border: solid 1px ${theme.colors.main.red};
            background: ${theme.colors.main.lightRed};
          `
        : css`
            border: solid 1px ${theme.colors.main.red};
            border-radius: 5px 5px 5px 0;
          `
      : null}
`;
