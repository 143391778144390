import css from "@emotion/css";

const globalStyles = css`
  @import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
  @import url("https://fonts.googleapis.com/icon?family=Material+Icons");
  @import url("https://fonts.googleapis.com/icon?family=Material+Icons+Outlined");

  :root {
    --color-darkGrey: #2c2c39;
    --color-darkRed: #cd0000;
    --color-green: #06c500;
    --color-lightBlue: #bbeafb;
    --color-lightGrey: #f5f5f6;
    --color-lightRed: #ffcece;
    --color-lightYellow: #fffade;
    --color-lightGreen: #eeffed;
    --color-mainBlue: #00b8ff;
    --color-mainGrey: #3b3b4d;
    --color-midGreen: #079503;
    --color-midGrey: #d8d8d8;
    --color-orange: #ffad00;
    --color-red: #f00;
    --color-yellow: #f0c233;
    --very-light-pink-two: #faece4;

    --transition-duration: 0.15s;
  }

  html {
    margin: 0;
    padding: 0;
  }

  body {
    min-height: 100%;
    color: var(--color-darkGrey);
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans",
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    line-height: 1.45;
    background: white;
  }
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
  }
  input,
  select,
  textarea,
  button {
    font-family: inherit;
    outline: 0;
  }
  .material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: "liga";
  }
  h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.38;
    margin: 0 0 20px;
  }
  h2 {
    font-size: 28px;
    font-weight: 400;
    line-height: 1.38;
    margin: 0 0 20px;
  }
  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.6;
    margin: 0 0 12px;
  }
  h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.6;
    margin: 0 0 10px;

    &:last-child {
      margin: 0;
    }
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    border-padding: 0;
    margin-bottom: 30px;
  }
  .link {
    color: var(--color-mainBlue);
    transition: color 0.25s;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: var(--color-darkBlue);
    }
  }
  .link-success {
    color: var(--color-green);
    transition: color 0.25s;

    &:hover {
      color: var(--color-midGreen);
    }
  }
  .link-button {
    text-decoration: none;
  }
  .d-f {
    display: flex;
  }
  .jc-sb {
    justify-content: space-between;
  }
  .avatar {
    background-color: white;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 50%;
    border: solid 1px var(--color-midGrey);

    &_medium {
      width: 140px;
      height: 140px;
    }
  }
  .text-danger {
    color: var(--color-red);
  }
  .text-warning {
    color: var(--color-orange);
  }
  .text-process {
    color: var(--color-green);
  }
  .text-success {
    color: var(--color-midGreen);
  }
  .bold {
    font-weight: 600;
  }
`;

export default globalStyles;
