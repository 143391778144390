/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";

import {
  BorderOutlined,
  CommentOutlined,
  FileDoneOutlined,
  FireOutlined,
  PoundOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { NavLink, withRouter } from "react-router-dom";

import Collapse from "../../ui/molecules/Collapse";
import Arrow from "./Arrow/Arrow";
import {
  BLOG_ADMINISTRATION_PATHS,
  MORTGAGES_PATHS,
  PRODUCTS_PATHS,
  PROPERTY_ADMINISTRATION_PATHS,
  REPORTS_PATHS,
  USERS_ADMINISTRATION_PATHS,
} from "./constants";

import styles from "./styles.module.css";

// WTF
// TODO: need more refactoring
const Sidebar = (props) => {
  const { pathname } = props.location;
  const isSectionOpened = (paths: string[]): boolean =>
    !!paths.find((pth) => ~pathname.indexOf(pth));

  return (
    <aside className={styles.sidebar}>
      <ul className={styles.menu}>
        <Collapse opened={isSectionOpened(USERS_ADMINISTRATION_PATHS)}>
          {({ isOpened, toggle }) => (
            <li className={styles.menuGroup}>
              <h4 className={styles.menuGroupHeader} onClick={toggle}>
                <UserOutlined className={styles.menuGroupIcon} />
                <span
                  className={styles.menuGroupTitle}
                  data-testid="sidemenu_users_administration_tab"
                >
                  Users administration
                </span>
                <Arrow isUpsideDown={isOpened} />
              </h4>
              {isOpened && (
                <ul className={styles.menuGroupList}>
                  <li className={styles.menuGroupListItem}>
                    <NavLink data-testid="platform_admins" to="/admin">
                      Admin Users
                    </NavLink>
                  </li>
                  <li className={styles.menuGroupListItem}>
                    <NavLink data-testid="platform_users" to="/users">
                      Platform Users
                    </NavLink>
                  </li>
                  <li className={styles.menuGroupListItem}>
                    <NavLink
                      data-testid="system-notifications"
                      to="/notifications"
                    >
                      System Notifications
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
          )}
        </Collapse>

        <Collapse opened={isSectionOpened(PROPERTY_ADMINISTRATION_PATHS)}>
          {({ isOpened, toggle }) => (
            <li className={styles.menuGroup}>
              <h4 className={styles.menuGroupHeader} onClick={toggle}>
                <BorderOutlined className={styles.menuGroupIcon} />
                <span
                  className={styles.menuGroupTitle}
                  data-testid="sidemenu_property_administration_tab"
                >
                  Property administration
                </span>
                <Arrow isUpsideDown={isOpened} />
              </h4>
              {isOpened && (
                <ul className={styles.menuGroupList}>
                  <li className={styles.menuGroupListItem}>
                    <NavLink data-testid="sidemenu_view_listings" to="/listing">
                      View Listing
                    </NavLink>
                  </li>
                  <li className={styles.menuGroupListItem}>
                    <NavLink data-testid="sidemenu_view_offers" to="/offers">
                      View Offers
                    </NavLink>
                  </li>
                  <li className={styles.menuGroupListItem}>
                    <NavLink
                      data-testid="sidemenu_view_tenancies"
                      to="/tenancies"
                    >
                      View Tenancies
                    </NavLink>
                  </li>
                  <li className={styles.menuGroupListItem}>
                    <NavLink
                      data-testid="sidemenu_view_invoices"
                      to="/invoices"
                    >
                      View Invoices
                    </NavLink>
                  </li>
                  <li className={styles.menuGroupListItem}>
                    <NavLink
                      data-testid="sidemenu_view_payments"
                      to="/payments"
                    >
                      View Payments
                    </NavLink>
                  </li>
                  <li className={styles.menuGroupListItem}>
                    <NavLink
                      data-testid="sidemenu_view_account_statement"
                      to="/statement"
                    >
                      Account statement
                    </NavLink>
                  </li>
                  <li className={styles.menuGroupListItem}>
                    <NavLink
                      data-testid="sidemenu_view_maintenance"
                      to="/maintenances"
                    >
                      Maintenance Requests
                    </NavLink>
                  </li>
                  <li className={styles.menuGroupListItem}>
                    <NavLink
                      data-testid="sidemenu_view_maintenance"
                      to="/invitations"
                    >
                      View Invitations
                    </NavLink>
                  </li>
                  <li className={styles.menuGroupListItem}>
                    <NavLink
                      data-testid="sidemenu_view_maintenance"
                      to="/unmatched-transactions"
                    >
                      Unmatched transactions
                    </NavLink>
                  </li>
                  <li className={styles.menuGroupListItem}>
                    <NavLink
                      data-testid="sidemenu_view_maintenance"
                      to="/overdue-payments"
                    >
                      Overdue payments
                    </NavLink>
                  </li>
                  <li className={styles.menuGroupListItem}>
                    <NavLink
                      data-testid="sidemenu_view_maintenance"
                      to="/payouts-made"
                    >
                      Payouts to be made
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
          )}
        </Collapse>

        <Collapse opened={isSectionOpened(PRODUCTS_PATHS)}>
          {({ isOpened, toggle }) => (
            <li className={styles.menuGroup}>
              <h4 className={styles.menuGroupHeader} onClick={toggle}>
                <FireOutlined className={styles.menuGroupIcon} />
                <span
                  className={styles.menuGroupTitle}
                  data-testid="sidemenu_products_tab"
                >
                  Products
                </span>
                <Arrow isUpsideDown={isOpened} />
              </h4>
              {isOpened && (
                <ul className={styles.menuGroupList}>
                  <li className={styles.menuGroupListItem}>
                    <NavLink
                      data-testid="sidemenu_view_all_products"
                      to="/products"
                    >
                      View all products
                    </NavLink>
                  </li>
                  <li className={styles.menuGroupListItem}>
                    <NavLink
                      data-testid="sidemenu_view_advanced_rent"
                      to="/advanced"
                    >
                      Advanced rent requests
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
          )}
        </Collapse>

        <Collapse opened={isSectionOpened(REPORTS_PATHS)}>
          {({ isOpened, toggle }) => (
            <li className={styles.menuGroup}>
              <h4 className={styles.menuGroupHeader} onClick={toggle}>
                <FileDoneOutlined className={styles.menuGroupIcon} />
                <span
                  className={styles.menuGroupTitle}
                  data-testid="sidemenu_reports_tab"
                >
                  Reports
                </span>
                <Arrow isUpsideDown={isOpened} />
              </h4>
              {isOpened && (
                <ul className={styles.menuGroupList}>
                  <li className={styles.menuGroupListItem}>
                    <NavLink
                      data-testid="sidemenu_view_property_events_log"
                      to="/events-property"
                    >
                      View Property Event logs
                    </NavLink>
                  </li>
                  <li className={styles.menuGroupListItem}>
                    <NavLink
                      data-testid="sidemenu_view_user_events_log"
                      to="/events-user"
                    >
                      View User Event logs
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
          )}
        </Collapse>

        <Collapse opened={isSectionOpened(MORTGAGES_PATHS)}>
          {({ isOpened, toggle }) => (
            <li className={styles.menuGroup}>
              <h4 className={styles.menuGroupHeader} onClick={toggle}>
                <PoundOutlined className={styles.menuGroupIcon} />
                <span
                  className={styles.menuGroupTitle}
                  data-testid="sidemenu_mortgages_tab"
                >
                  Mortgages
                </span>
                <Arrow isUpsideDown={isOpened} />
              </h4>
              {isOpened && (
                <ul className={styles.menuGroupList}>
                  <li className={styles.menuGroupListItem}>
                    <NavLink
                      data-testid="sidemenu_leadlist"
                      to="/mortgages-lead-list"
                    >
                      Lead list
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
          )}
        </Collapse>

        <Collapse opened={isSectionOpened(BLOG_ADMINISTRATION_PATHS)}>
          {({ isOpened, toggle }) => (
            <li className={styles.menuGroup}>
              <h4 className={styles.menuGroupHeader} onClick={toggle}>
                <CommentOutlined className={styles.menuGroupIcon} />
                <span
                  className={styles.menuGroupTitle}
                  data-testid="sidemenu_users_administration_tab"
                >
                  Blog administration
                </span>
                <Arrow isUpsideDown={isOpened} />
              </h4>
              {isOpened && (
                <ul className={styles.menuGroupList}>
                  <li className={styles.menuGroupListItem}>
                    <NavLink data-testid="blog_comments" to="/comments">
                      Comments overview
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
          )}
        </Collapse>
      </ul>
    </aside>
  );
};

export default withRouter(Sidebar);
