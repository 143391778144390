import React, { useCallback } from "react";

import styled from "@emotion/styled";
import { Field, Form } from "react-final-form";
import ReCAPTCHA from "react-google-recaptcha";

import { PrimaryButton } from "../../ui/atoms/Button";
import { TextInput } from "../../ui/atoms/Input";
import theme from "../../ui/theme";
import { RECAPTCHA_SITE_KEY } from "../../util/constants";
import { required } from "../../util/validations";

type LoginProps = {
  onSubmit: any;
  errorMessage?: string;
  handleCloseError?: any;
  isCaptchaRequired?: boolean;
};

const ReCaptchaField: React.FC<any> = ({
  input: { onChange, ...inputRest }, // eslint-disable-line react/prop-types
  handleCloseError,
  ...rest
}: any) => (
  <ReCAPTCHA
    onChange={useCallback(
      (v) => {
        onChange(v);
        handleCloseError();
      },
      [onChange, handleCloseError]
    )}
    sitekey={RECAPTCHA_SITE_KEY}
  />
);

const Login: React.FC<LoginProps> = ({
  onSubmit,
  errorMessage,
  handleCloseError,
  isCaptchaRequired,
}) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, hasValidationErrors, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormFrame>
              <FormTitle>Login</FormTitle>
              {errorMessage && (
                <ErrorMessage>
                  <span>{errorMessage}</span>
                  <ErrorMessageClose onClick={handleCloseError}>
                    <i className="material-icons">close</i>
                  </ErrorMessageClose>
                </ErrorMessage>
              )}
              <FormRow>
                <FormLabel>Email</FormLabel>
                <Field
                  component={TextInput}
                  name="email"
                  type="email"
                  validate={required()}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Password</FormLabel>
                <Field
                  component={TextInput}
                  name="password"
                  type="password"
                  validate={required()}
                />
              </FormRow>
              {isCaptchaRequired && (
                <FormRow>
                  <Field
                    key="recaptcha"
                    component={ReCaptchaField}
                    handleCloseError={handleCloseError}
                    name="recaptcha"
                  />
                </FormRow>
              )}
              <PrimaryButton
                disabled={submitting || hasValidationErrors}
                style={{ width: "100%" }}
              >
                Login
              </PrimaryButton>
            </FormFrame>
          </form>
        );
      }}
    />
  );
};

const FormTitle = styled.h3`
  margin: 0 0 30px;
  text-align: center;
  font-size: 24px;
`;
const FormFrame = styled.div`
  padding: 30px 30px 30px;
  background: white;
  box-shadow: ${theme.boxShadow.grey};
  border-radius: 10px;
  width: 360px;
`;
const FormRow = styled.div`
  margin: 0 0 30px;
`;
const FormLabel = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 7px;
`;
const ErrorMessage = styled.div`
  border: solid 1px ${theme.colors.main.red};
  padding: 10px 20px;
  color: ${theme.colors.main.red};
  text-align: left;
  margin: 0 0 20px;
  background: white;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
const ErrorMessageClose = styled.div`
  padding: 5px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0);
  color: red;
  font-size: 14px;
  line-height: 1;
  position: relative;
  left: 15px;
`;

export default Login;
