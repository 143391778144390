import React from "react";

import { ArrowProps } from "./types";

import styles from "../styles.module.css";

const Arrow = ({ isUpsideDown }: ArrowProps) => (
  <span className={styles.arrow}>
    <i className="material-icons">
      {isUpsideDown ? "expand_less" : "expand_more"}
    </i>
  </span>
);

export default Arrow;
